import React from "react";
import TabsView from "components/MainCard/TabsView";
import HistoryView from "components/History/View";
import Header from "Mobile/components/Header/Header";
import { Divider } from "antd";

const History = () => {
  return (
    <div className="my-3">
      <Header headerName="History" />
      <Divider />
      <TabsView isMobile>
        <HistoryView isMobile />
      </TabsView>
    </div>
  );
};

export default History;
