import React, { useRef, useState } from "react";
import { inject, observer } from "mobx-react";
import { Modal, Typography } from "antd";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import AppButton from "components/AppButton/AppButton";
import styles from "assets/css/modals.module.css";
import AlertModal from "./AlertModal";
import FormSelectInput from "components/FormItems/FormSelectInput";

const AssignModal = props => {
  const {
    visible,
    onCancel,
    template = false,
    campaign = false,
    authStore,
    subAccountStore,
    templateStore,
    groupStore,
    gatewayBulkStore
  } = props;
  const { user } = authStore;
  const { assignTemplate, assignGroup, assignBulk } = subAccountStore;
  const { selectedTemplate, selectTemplate } = templateStore;
  const { selectedGroup, selectGroup } = groupStore;
  const { selectedBulk, selectBulk } = gatewayBulkStore;
  const [successAlert, setSuccessAlert] = useState(false);
  const formikRef = useRef();

  if (!user) return null;

  let initialValues = {
    subAccount: ""
  };

  if (template) {
    initialValues.template = selectedTemplate?._id;
  } else if (campaign) {
    initialValues.campaign = selectedBulk?._id;
  } else {
    initialValues.group = selectedGroup?._id;
  }
  return (
    <>
      <Modal
        visible={visible}
        title={
          <div className="text-center">
            <h4 className="pt-3 fw-bold">
              {template
                ? "Assign Template"
                : campaign
                ? "Assign Saved Campaign"
                : "Assign Group"}
            </h4>
          </div>
        }
        onCancel={() => {
          onCancel();
          formikRef.current.resetForm();
          selectTemplate(null);
          selectGroup(null);
          selectBulk(null);
        }}
        closeIcon={
          <div className={`btn ${styles.modalCloseBtn}`} type="button">
            <i className="fas fa-times"></i>
          </div>
        }
        footer={null}
        centered
        destroyOnClose
        maxWidth="550px"
        maskStyle={{
          background: "linear-gradient(135deg, #00000050, #107c9850)"
        }}
      >
        <Formik
          enableReinitialize
          initialValues={initialValues}
          innerRef={formikRef}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            if (template) {
              assignTemplate(values)
                .then(res => {
                  if (res.status) {
                    setSuccessAlert(true);
                  }
                })
                .finally(() => {
                  onCancel();
                  selectTemplate(null);
                  setSubmitting(false);
                });
            } else if (campaign) {
              assignBulk(values)
                .then(res => {
                  if (res.status) {
                    setSuccessAlert(true);
                  }
                })
                .finally(() => {
                  onCancel();
                  selectBulk(null);
                  setSubmitting(false);
                });
            } else {
              assignGroup(values)
                .then(res => {
                  if (res.status) {
                    setSuccessAlert(true);
                  }
                })
                .finally(() => {
                  onCancel();
                  selectGroup(null);
                  setSubmitting(false);
                });
            }
          }}
          validationSchema={Yup.object().shape({
            subAccount: Yup.string().required("Required")
          })}
        >
          {({
            values,
            errors,
            touched,
            setFieldValue,
            handleSubmit,
            isSubmitting
          }) => (
            <Form onSubmit={handleSubmit} className="px-4 text-center">
              <Typography.Title level={4} className="text-dark mb-3">
                {template ? (
                  <>
                    Template Name:{" "}
                    <span className="text-info-color">
                      {selectedTemplate?.name}
                    </span>
                  </>
                ) : campaign ? (
                  <>
                    Saved Campaign Name:{" "}
                    <span className="text-info-color">
                      {selectedBulk?.name}
                    </span>
                  </>
                ) : (
                  <>
                    Group Name:{" "}
                    <span className="text-info-color">
                      {selectedGroup?.name}
                    </span>
                  </>
                )}
              </Typography.Title>
              <FormSelectInput
                name="subAccount"
                options={user.subAccount.map(account => ({
                  // label: account,
                  // value: account
                  label: account.email,
                  value: account._id
                }))}
                size="default"
                containerClassnames="mb-3"
                placeholder="Select Sub Accounts"
                onChange={value => {
                  setFieldValue("subAccount", value);
                }}
                capitalizeLabel={false}
                value={values.subAccount ? values.subAccount : undefined}
                error={errors.subAccount}
                touched={touched.subAccount}
              />
              <AppButton
                light
                type="submit"
                label="Assign"
                prefixIcon={<i className="me-2 fas fa-file-signature"></i>}
                disabled={isSubmitting}
              />
            </Form>
          )}
        </Formik>
      </Modal>
      <AlertModal
        visible={successAlert}
        title="Success"
        description={
          template
            ? "Template assigned successfully"
            : campaign
            ? "Saved Campaign assigned successfully"
            : "Group assigned successfully"
        }
        primaryBtnProps={{ onClick: () => setSuccessAlert(false), label: "Ok" }}
        closable
        onCancel={() => setSuccessAlert(false)}
      />
    </>
  );
};

export default inject(stores => ({
  subAccountStore: stores.store.subAccountStore,
  authStore: stores.store.authStore,
  templateStore: stores.store.templateStore,
  groupStore: stores.store.groupStore,
  gatewayBulkStore: stores.store.gatewayBulkStore
}))(observer(AssignModal));
