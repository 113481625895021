import React from "react";
import bugsnag from "@bugsnag/js";
import bugsnagReact from "@bugsnag/plugin-react";

const bugsnagClient = bugsnag({
  apiKey: process.env.REACT_APP_BUGSNAG_KEY,
  releaseStage: process.env.NODE_ENV
});
bugsnagClient.use(bugsnagReact, React);

export default bugsnagClient;
