import React from "react";
import { Row, Col, Typography, Radio } from "antd";
import FormInput from "components/FormItems/FormInput";
import AppButton from "components/AppButton/AppButton";
import GroupLogo from "assets/images/common/group.png";
import * as Yup from "yup";
import { FieldArray, Form, Formik } from "formik";
import { inject, observer } from "mobx-react";
import FormSelectInput from "components/FormItems/FormSelectInput";
import { toJS } from "mobx";
import { isEmpty } from "lodash";

const GroupForm = ({ setStep = () => {}, app, onSuccess = () => {}, onBack = () => {}, integration, groupStore }) => {
  const { setData, activeCampFilter, hubspotFieldList, zohoFieldList, loading } = integration;
  const { group } = groupStore;

  return (
    <Formik
      enableReinitialize
      initialValues={{
        name: "",
        zohoType: "",
        filter: "",
        tagid: "",
        listid: app === "hubspot" ? [] : "",
        status: "-1",
        leadStatus: "",
        emailMarketing: "yes",
        emailOneToOne: "yes",
        // filterGroups: [
        //   {
        //     filters: [
        //       {
        //         propertyName: "",
        //         operator: "",
        //         value: ""
        //       }
        //     ]
        //   }
        // ],
        zohoFilter: [{ field: "", operator: "", value: "" }]
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().when([], {
          is: () => !group,
          then: Yup.string()
            .min(1, "Name should be at least 1 character long")
            .required("Group name must be provided")
        }),
        zohoType: Yup.string().when([], {
          is: () => app === "zoho",
          then: Yup.string().required("Type must be provided")
        }),
        filter: Yup.string().when([], {
          is: () => app === "ac" || app === "hubspot",
          then: Yup.string().required("You must select an Filter")
        }),
        leadStatus: Yup.string().when(["filter"], {
          is: _ => app === "hubspot" && _ === "lead",
          then: Yup.string().required("Select status")
        }),
        listid: Yup.string().when(["filter"], {
          is: _ => app === "hubspot" && _ === "list",
          then: Yup.string().required("Select any list")
        }),
        status: Yup.string().when([], {
          is: () => app === "ac",
          then: Yup.string().required("Status must be provided")
        })
        // filterGroups: Yup.array().when("filter", {
        //   is: "custom",
        //   then: Yup.array()
        //     .of(
        //       Yup.object().shape({
        //         filters: Yup.array()
        //           .of(
        //             Yup.object().shape({
        //               propertyName: Yup.string().required("Property is required"),
        //               operator: Yup.string().required("Operator is required"),
        //               value: Yup.string().when("operator", {
        //                 is: operator => operator && operator !== "HAS_PROPERTY" && operator !== "NOT_HAS_PROPERTY",
        //                 then: Yup.string().required("Value is required"),
        //                 otherwise: Yup.string().nullable()
        //               })
        //             })
        //           )
        //           .min(1, "At least one filter is required")
        //       })
        //     )
        //     .required("Filter group is required")
        //     .min(1, "At least one filter group is required"),
        //   otherwise: Yup.array().of(
        //     Yup.object().shape({
        //       filters: Yup.array().of(
        //         Yup.object().shape({
        //           propertyName: Yup.string().nullable(),
        //           operator: Yup.string().nullable(),
        //           value: Yup.string().nullable()
        //         })
        //       )
        //     })
        //   )
        // })
      })}
      onSubmit={(values, { setSubmitting }) => {
        setStep();
        setData("groupName", values.name);
        onSuccess(values);
        setSubmitting(false);
      }}
    >
      {({ values, errors, touched, handleChange, setFieldValue, isSubmitting }) => {
        return (
          <Form className="pt-3">
            <Row type="flex" justify="center">
              <Col span={24} className="text-center mb-3">
                <img src={GroupLogo} alt="group" />
                {/* <p className="text-muted text-dark fs-6 mt-3">Create your new group in which we import synced contacts.</p> */}
              </Col>
              {!group && (
                <Col xs={24} md={16} className="text-center">
                  <FormInput placeholder="Enter Group Name" name="name" value={values.name} onChange={handleChange} touched={touched.name} error={errors.name} />
                </Col>
              )}
              {app === "zoho" && (
                <>
                  <Col xs={24} md={16} className="text-center mb-3">
                    <FormSelectInput
                      allowClear
                      name="zohoType"
                      value={values.zohoType}
                      error={errors.zohoType}
                      touched={touched.zohoType}
                      containerClassnames="mb-0"
                      selectEleClassnames="w-100"
                      placeholder="Choose CRM Module"
                      onChange={e => {
                        setData("zohoFieldList", e, "type");
                        setFieldValue("zohoType", e);
                      }}
                      options={[
                        { label: "Contacts", value: "Contacts" },
                        { label: "Leads", value: "Leads" }
                      ]}
                    />
                  </Col>
                  {!isEmpty(values.zohoType) && (
                    <>
                      <Col span={24} className="text-center">
                        <h5 className="mb-3 fw-bold">Import by filter</h5>
                        <Radio.Group
                          className="w-100 text-wrap"
                          onChange={event => {
                            setFieldValue("filter", event.target.value);
                          }}
                          value={values.filter}
                          name="filter"
                          error={errors.filter}
                          touched={touched.filter}
                        >
                          <Radio className="mb-3 text-wrap" value="all">
                            All
                          </Radio>
                          <Radio className="mb-3 text-wrap" value="criteria">
                            Criteria
                          </Radio>
                        </Radio.Group>
                        {errors?.filter && touched?.filter && <div className="text-danger-color">{errors.filter}</div>}
                      </Col>
                      {values?.filter === "criteria" && (
                        <Col xs={24} md={20} className="text-center">
                          <FieldArray
                            name="zohoFilter"
                            render={helper => (
                              <>
                                {values.zohoFilter.map((_, i) => {
                                  return (
                                    <Row key={i} align="stretch" className="mb-3">
                                      <Col md={24}>
                                        <Row gutter={[10, 10]}>
                                          <Col xs={24} md={12}>
                                            <FormSelectInput
                                              allowClear
                                              showSearch
                                              size="default"
                                              optionConfig={{
                                                label: "label",
                                                value: "name"
                                              }}
                                              selectProps={{ loading }}
                                              name={`zohoFilter[${i}].field`}
                                              value={values?.zohoFilter?.[i]?.field}
                                              error={errors?.zohoFilter?.[i]?.field}
                                              touched={touched?.zohoFilter?.[i]?.field}
                                              containerClassnames="mb-0"
                                              placeholder="Select property"
                                              selectEleClassnames="w-100"
                                              options={zohoFieldList?.list || []}
                                              onChange={e => {
                                                console.log(e);
                                                setFieldValue(`zohoFilter[${i}].field`, e);
                                              }}
                                            />
                                          </Col>
                                          <Col xs={24} md={12}>
                                            <FormSelectInput
                                              allowClear
                                              size="default"
                                              containerProps={{ colon: false }}
                                              containerClassnames="mb-0"
                                              selectEleClassnames="w-100"
                                              placeholder="Select operator"
                                              options={zohoFieldList?.operator || []}
                                              name={`zohoFilter[${i}].operator`}
                                              value={values?.zohoFilter?.[i]?.operator}
                                              error={errors?.zohoFilter?.[i]?.operator}
                                              touched={touched?.zohoFilter?.[i]?.operator}
                                              onChange={e => setFieldValue(`zohoFilter[${i}].operator`, e)}
                                            />
                                          </Col>
                                          <Col xs={24} md={24}>
                                            <FormInput
                                              type="text"
                                              label={null}
                                              size="default"
                                              placeholder="Enter value"
                                              containerClassname="mb-0"
                                              containerProps={{ colon: false }}
                                              name={`zohoFilter[${i}].value`}
                                              value={values?.zohoFilter?.[i]?.value}
                                              error={errors?.zohoFilter?.[i]?.value}
                                              touched={touched?.zohoFilter?.[i]?.value}
                                              onChange={handleChange}
                                            />
                                          </Col>
                                        </Row>
                                      </Col>
                                      {i !== 0 && (
                                        <Col md={24} className="text-end">
                                          <button
                                            className={"btn-outline-danger rounded border-0 mt-2"}
                                            onClick={e => {
                                              e.preventDefault();
                                              helper.remove(i);
                                            }}
                                          >
                                            <i class="fas fa-times p-2"></i>
                                          </button>
                                        </Col>
                                      )}
                                    </Row>
                                  );
                                })}
                                <div className="text-end">
                                  <button
                                    className={"btn-outline-secondary rounded border-0 mt-2"}
                                    onClick={e => {
                                      e.preventDefault();
                                      helper.push({ field: "", operator: "", value: "" });
                                    }}
                                  >
                                    <i class="fas fa-plus p-2"></i>
                                  </button>
                                </div>
                              </>
                            )}
                          />
                        </Col>
                      )}
                    </>
                  )}
                </>
              )}
              {app === "ac" && (
                <>
                  <Col span={24} className="text-center">
                    <h5 className="mb-3 fw-bold">Import Contact</h5>
                    <Radio.Group
                      className="w-100 text-wrap"
                      onChange={event => {
                        setFieldValue("filter", event.target.value);
                      }}
                      value={values.filter}
                      name="filter"
                      error={errors.filter}
                      touched={touched.filter}
                    >
                      <Radio className="mb-3 text-wrap" value="all">
                        All
                      </Radio>
                      <Radio className="mb-3 text-wrap" value="by">
                        By
                      </Radio>
                    </Radio.Group>
                    {errors?.filter && touched?.filter && <div className="text-danger-color">{errors.filter}</div>}
                  </Col>
                  {values?.filter === "by" && (
                    <Col xs={24} md={16} className="text-center">
                      <FormSelectInput
                        allowClear
                        name="status"
                        value={values.status}
                        error={errors.status}
                        touched={touched.status}
                        containerClassnames="mb-3"
                        selectEleClassnames="w-100"
                        placeholder="Select Status"
                        onChange={e => setFieldValue("status", e)}
                        options={activeCampFilter?.status || []}
                      />
                      <FormSelectInput
                        allowClear
                        name="tagid"
                        value={values.tagid}
                        error={errors.tagid}
                        touched={touched.tagid}
                        containerClassnames="mb-3"
                        selectEleClassnames="w-100"
                        placeholder="Select Tag"
                        onChange={e => setFieldValue("tagid", e)}
                        options={activeCampFilter?.tag || []}
                      />
                      <FormSelectInput
                        allowClear
                        name="listid"
                        value={values.listid}
                        error={errors.listid}
                        touched={touched.listid}
                        containerClassnames="mb-3"
                        selectEleClassnames="w-100"
                        placeholder="Select List"
                        onChange={e => setFieldValue("listid", e)}
                        options={activeCampFilter?.list || []}
                      />
                    </Col>
                  )}
                </>
              )}
              {app === "hubspot" && (
                <>
                  <Col span={24} className="text-center">
                    <h5 className="mb-3 fw-bold">Import by filter</h5>
                    <Radio.Group
                      className="w-100 text-wrap"
                      onChange={event => {
                        setFieldValue("filter", event.target.value);
                      }}
                      value={values.filter}
                      name="filter"
                      error={errors.filter}
                      touched={touched.filter}
                    >
                      <Radio className="mb-3 text-wrap" value="all">
                        All
                      </Radio>
                      <Radio className="mb-3 text-wrap" value="list">
                        List
                      </Radio>
                      {/* <Radio className="mb-3 text-wrap" value="lead">
                        Lead Status
                      </Radio>
                      <Radio className="mb-3 text-wrap" value="custom">
                        Custom
                      </Radio> */}
                    </Radio.Group>
                    {errors?.filter && touched?.filter && <div className="text-danger-color">{errors.filter}</div>}
                  </Col>
                  {values?.filter === "list" && (
                    <>
                      <Col xs={24} md={16} className="text-center">
                        <FormSelectInput
                          allowClear
                          name="listid"
                          size="default"
                          value={values.listid}
                          error={errors.listid}
                          touched={touched.listid}
                          containerClassnames="mb-3"
                          placeholder="Select any list"
                          selectEleClassnames="w-100"
                          options={hubspotFieldList?.lists || []}
                          onChange={e => setFieldValue("listid", e)}
                        />
                      </Col>
                      <Col xs={24} md={16} className="text-center">
                        <FormSelectInput
                          allowClear
                          size="default"
                          containerProps={{ colon: false }}
                          showSearch
                          containerClassnames="mb-3"
                          selectEleClassnames="w-100"
                          label="Exclude Opted-out of Email Marketing ?"
                          options={[
                            { label: "Yes", value: "yes" },
                            { label: "No", value: "no" }
                          ]}
                          name={`emailMarketing`}
                          value={values?.emailMarketing}
                          error={errors?.emailMarketing}
                          touched={touched?.emailMarketing}
                          onChange={e => setFieldValue(`emailMarketing`, e)}
                        />
                      </Col>
                      <Col xs={24} md={16} className="text-center">
                        <FormSelectInput
                          allowClear
                          size="default"
                          containerProps={{ colon: false }}
                          showSearch
                          containerClassnames="mb-3"
                          selectEleClassnames="w-100"
                          label="Exclude Opted-out of Email: One to One ?"
                          options={[
                            { label: "Yes", value: "yes" },
                            { label: "No", value: "no" }
                          ]}
                          name={`emailOneToOne`}
                          value={values?.emailOneToOne}
                          error={errors?.emailOneToOne}
                          touched={touched?.emailOneToOne}
                          onChange={e => setFieldValue(`emailOneToOne`, e)}
                        />
                      </Col>
                    </>
                  )}
                  {/* {values?.filter === "lead" && (
                    <Col xs={24} md={16} className="text-center">
                      <FormSelectInput
                        allowClear
                        mode="multiple"
                        size="default"
                        name="leadStatus"
                        value={values.leadStatus}
                        error={errors.leadStatus}
                        containerClassnames="mb-3"
                        selectEleClassnames="w-100"
                        placeholder="Select Status"
                        touched={touched.leadStatus}
                        options={hubspotFieldList?.leadStatus || []}
                        onChange={e => setFieldValue("leadStatus", e)}
                      />
                    </Col>
                  )} */}
                  {/* {values?.filter === "custom" && (
                    <Col xs={24} md={16} className="text-center">
                      <FieldArray
                        name="filterGroups[0].filters"
                        render={helper =>
                          values.filterGroups[0].filters.map((_, i) => {
                            return (
                              <Row key={i}>
                                <Col xs={24} md={24} className="text-center">
                                  <FormSelectInput
                                    allowClear
                                    size="default"
                                    optionConfig={{
                                      label: "label",
                                      value: "name"
                                    }}
                                    showSearch
                                    containerClassnames="mb-3"
                                    selectEleClassnames="w-100"
                                    placeholder="Select property"
                                    options={hubspotFieldList?.fields || []}
                                    name={`filterGroups[0].filters[${i}].propertyName`}
                                    value={values?.filterGroups?.[0]?.filters?.[i]?.propertyName}
                                    error={errors?.filterGroups?.[0]?.filters?.[i]?.propertyName}
                                    touched={touched.filterGroups?.[0]?.filters?.[i]?.propertyName}
                                    onChange={e => setFieldValue(`filterGroups[0].filters[${i}].propertyName`, e)}
                                  />
                                </Col>
                                <Col xs={24} md={24} className="text-center">
                                  <FormSelectInput
                                    allowClear
                                    size="default"
                                    selectProps={{
                                      filterOption: (input, option) => option.props.label.toLowerCase().includes(input.toLowerCase())
                                    }}
                                    containerClassnames="mb-3"
                                    selectEleClassnames="w-100"
                                    placeholder="Select filter"
                                    options={hubspotFieldList?.filter || []}
                                    name={`filterGroups[0].filters[${i}].operator`}
                                    value={values?.filterGroups?.[0]?.filters?.[i]?.operator}
                                    error={errors?.filterGroups?.[0]?.filters?.[i]?.operator}
                                    touched={touched?.filterGroups?.[0]?.filters?.[i]?.operator}
                                    onChange={e => setFieldValue(`filterGroups[0].filters[${i}].operator`, e)}
                                  />
                                </Col>
                                {values?.filterGroups?.[0]?.filters?.[i].operator && !["HAS_PROPERTY", "NOT_HAS_PROPERTY"].includes(values?.filterGroups?.[0]?.filters?.[i].operator) && (
                                  <Col xs={24} md={24} className="text-center">
                                    <FormInput
                                      type="text"
                                      label={null}
                                      size="default"
                                      placeholder="Value"
                                      containerClassname="mb-0"
                                      containerProps={{ colon: false }}
                                      name={`filterGroups[0].filters[${i}].value`}
                                      value={values?.filterGroups?.[0]?.filters?.[i]?.value}
                                      error={errors?.filterGroups?.[0]?.filters?.[i]?.value}
                                      touched={touched?.filterGroups?.[0]?.filters?.[i]?.value}
                                      onChange={e => handleChange(e)}
                                    />
                                  </Col>
                                )}
                              </Row>
                            );
                          })
                        }
                      />
                    </Col>
                  )} */}
                </>
              )}
              <Col span={24} className="vh-center gap-3 mt-3 text-center">
                <AppButton label={"Previous"} light withoutBg isSubmitting={isSubmitting} prefixIcon={<i className="bx bx-left-arrow-alt me-1" />} onClick={onBack} />
                <AppButton label={"Next"} type="submit" light isSubmitting={isSubmitting} postfixIcon={<i className="bx bx-right-arrow-alt ms-1" />} />
              </Col>
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
};

export default inject(stores => ({
  groupStore: stores.store.groupStore,
  integration: stores.store.integrationStore
}))(observer(GroupForm));
