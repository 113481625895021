import React, { useState } from "react";
import { Col, Checkbox, Row, Typography } from "antd";
import AppButton from "components/AppButton/AppButton";

const CampaignOptout = props => {
  const {
    setFieldValue,
    values,
    label = "Add Opt-Out Link",
    name = "optout",
    disabled = false
  } = props;
  const [optout, setOptout] = useState("");
  const [alertConfirm, setAlertConfirm] = useState(false);

  const types = [
    {
      title: label,
      description:
        name == "replyStopToOptOut"
          ? "Recipients can unsubscribe by replying STOP to your message"
          : "Mandatory for marketing messages. Contacts will be moved to ‘Opted out’ when they click on the link.",
      value: name
    }
  ];

  return (
    <Checkbox.Group
      disabled={disabled}
      value={[optout]}
      className="d-flex gap-3 campaign-radio"
    >
      <Row type="flex" gutter={[10, 10]}>
        {types.map((type, i) => (
          <Col span={24} md={alertConfirm ? 20 : 10} sm={12} key={i}>
            <Checkbox
              className={` w-100 py-4 rounded-xl px-2 d-flex campaign-checkbox`}
              value={type.value}
              checked={values[name]}
              style={{
                border: `1px solid ${values[name] ? "#149cbe" : "#dee2e6"}`
              }}
            >
              <Row
                className="position-relative h-100"
                type="flex"
                gutter={[10, 10]}
              >
                <Col
                  span={24}
                  md={alertConfirm ? 12 : 24}
                  onClick={() => {
                    if (values[name] && !alertConfirm) {
                      setAlertConfirm(true);
                    } else if (!values[name]) {
                      setOptout(name);
                      setFieldValue(name, true);
                    }
                  }}
                >
                  <Row type="flex" className="h-100">
                    <Col span={24}>
                      <Row type="flex">
                        <Col span={24}>
                          <Typography.Title level={4} className="fs-6">
                            {type.title}
                          </Typography.Title>
                        </Col>
                        <Col span={24} className="lh-1">
                          <Typography.Text className="fs-8">
                            {type.description}
                          </Typography.Text>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={24} className="align-self-end">
                      {values[name] ? (
                        <i className="fas fa-check-circle text-info-color" />
                      ) : (
                        <i className="far fa-circle text-info-color" />
                      )}
                    </Col>
                  </Row>
                </Col>
                {alertConfirm && (
                  <Col span={24} md={12}>
                    <Typography.Text className="fs-8">
                      As per ACMA guidelines, all marketing or promotional
                      messages must allow the recipient to opt-out from
                      receiving messages in the future.
                      <br />
                      For more information please visit &nbsp;
                      <a
                        href={"https://www.acma.gov.au/"}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        www.acma.gov.au
                      </a>
                      .<br />
                      Are you sure to exclude opt-out from campaign?
                    </Typography.Text>
                    <div className="d-flex justify-content-end mt-2">
                      <AppButton
                        light
                        withoutBg
                        label="Cancel"
                        size="small"
                        onClick={() => setAlertConfirm(false)}
                      />
                      <AppButton
                        light
                        label="Confirm"
                        size="small"
                        className="ms-2"
                        onClick={() => {
                          setOptout("");
                          setFieldValue(name, false);
                          setAlertConfirm(false);
                        }}
                      />
                    </div>
                  </Col>
                )}
              </Row>
            </Checkbox>
          </Col>
        ))}
      </Row>
    </Checkbox.Group>
  );
};

export default CampaignOptout;
