import { decorate, observable, runInAction } from "mobx";
import {
  sendMail,
  getMailMessage,
  sendEnqiuryMail
} from "service/supportService";

class ObservableSupportStore {
  sendMailResponse = undefined;
  sendMailParams = {
    message_body: "",
    subject: ""
  };
  getMailMessageResponse = undefined;
  enqiuryMailResponse = undefined;

  sendMail = async params => {
    this.sendMailParams = params;
    this.sendMailResponse = await sendMail(this.sendMailParams);
    runInAction(() => {
      this.sendMailResponse = this.sendMailResponse;
    });
    return this.sendMailResponse;
  };

  getMailMessage = async params => {
    this.getMailMessageResponse = await getMailMessage(params);
    runInAction(() => {
      this.getMailMessageResponse = this.getMailMessageResponse;
    });
    return this.getMailMessageResponse;
  };

  sendEnqiuryMail = async data => {
    const sendEnqiuryMailResponse = await sendEnqiuryMail(data);
    try {
      runInAction(() => {
        this.enqiuryMailResponse = sendEnqiuryMailResponse;
      });
    } catch (error) {
      this.enqiuryMailResponse = error;
    }
    return this.enqiuryMailResponse;
  };
}

decorate(ObservableSupportStore, {
  sendMailResponse: observable,
  sendMailParams: observable,
  getMailMessageResponse: observable,
  enqiuryMailResponse: observable
});
export default new ObservableSupportStore();
