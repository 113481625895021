const fileSupportList = [
  "image/jpg",
  "image/jpeg",
  "image/gif",
  "image/png",
  "audio/mpeg",
  "audio/3gpp",
  "audio/amr",
  "audio/vnd.qcelp",
  "audio/3gpp2",
  "audio/x-wav",
  "audio/wave",
  "audio/wav",
  "audio/mp3",
  "audio/ogg",
  "audio/qcelp",
  "audio/evrc",
  "audio/mp4",
  "audio/x-ms-wma",
  "video/3gpp",
  "video/x-flx",
  "video/3gpp2",
  "video/mp4",
  "video/x-ms-asf",
  "video/x-ms-wmv",
  "video/ogg",
  "video/x-sgi-movie",
  "application/pdf"
];

const imageSupportList = ["image/jpg", "image/jpeg", "image/gif", "image/png"];

const audioSupportList = [
  "audio/mpeg",
  "audio/3gpp",
  "audio/amr",
  "audio/vnd.qcelp",
  "audio/3gpp2",
  "audio/x-wav",
  "audio/wave",
  "audio/wav",
  "audio/mp3",
  "audio/ogg",
  "audio/qcelp",
  "audio/evrc",
  "audio/mp4",
  "audio/x-ms-wma"
];

const videoSupportList = [
  "video/3gpp",
  "video/x-flx",
  "video/3gpp2",
  "video/mp4",
  "video/x-ms-asf",
  "video/x-ms-wmv",
  "video/ogg",
  "video/x-sgi-movie"
];

export {
  fileSupportList,
  imageSupportList,
  videoSupportList,
  audioSupportList
};
