import { get, post, put, deleteRequest } from "utils/api";
/**
 * Call get all test case api
 * @returns {Promise<any>}
 */
export const getAllParam = async params => {
  // save api response for Param List list
  const paramListResponse = await get(`/param`, params);
  return paramListResponse.data;
};

/**
 * Add new Param Case api
 * @param paramData
 * @returns {Promise<any>}
 */
export const addParam = async (paramData = {}) => {
  const addResponse = await post(`/param`, paramData);
  return addResponse.data;
};

/**
 * Update existing Param Case Api
 * @param paramData
 * @param paramId
 * @returns {Promise<any>}
 */
export const updateParam = async (paramData = {}, paramId) => {
  const updateResponse = await put(`/param/${paramId}`, paramData);
  return updateResponse.data;
};

/**
 * Delete Param Case
 * @param paramId
 * @returns {Promise<any>}
 */
export const deleteParam = async paramId => {
  const deleteResponse = await deleteRequest(`/param/${paramId}`);
  return deleteResponse.data;
};
