import React, { useEffect } from "react";
import { inject, observer } from "mobx-react";
import { ErrorBoundary } from "react-error-boundary";
import ErrorBoundaryFallback from "components/ErrorBoundary/ErrorBoundaryFallback";

function DataWrapper(props) {
  const { authStore } = props;
  useEffect(() => {
    fetchIntialData();
  }, [authStore.token]);

  async function fetchIntialData() {
    //fetch user
    await authStore.getUserDetails().then(user => {
      // console.log({ user });
    });
    //fetch balance
    await authStore.getMyBalance().then(balance => {
      // console.log({ balance });
    });
  }
  function handleOnError(error, info) {
    console.error(error);
    console.log(info);
  }
  return (
    <>
      <ErrorBoundary
        FallbackComponent={ErrorBoundaryFallback}
        onError={handleOnError}
      >
        {props.children}
      </ErrorBoundary>
    </>
  );
}

export default inject(stores => ({
  authStore: stores.store.authStore
}))(observer(DataWrapper));
