import { decorate, observable, runInAction } from "mobx";
import {
  getAllMobileList,
  updatePhone,
  deletePhone,
  getPhoneHistory
} from "service/mobilePhoneService";
import _ from "lodash";

class ObservablePhoneStore {
  mobileList = [];
  selectedMobile = undefined;
  updatePhoneResponse = undefined;
  deletePhoneResponse = undefined;
  phoneHistoryResponse = [];

  /**
   * get all mobile list
   * @returns {Promise<void>}
   */
  getAllMobileList = async () => {
    // call & save mobile list response
    const mobileListResponse = await getAllMobileList();
    try {
      runInAction(() => {
        this.mobileList = mobileListResponse;
      });
    } catch (error) {
      runInAction(() => {
        this.status = "error";
      });
    }

    return this.mobileList;
  };

  /**
   * select Device Data for Update
   * @param deviceData
   */
  selectMobile = deviceData => {
    this.selectedMobile = deviceData
      ? JSON.parse(JSON.stringify(deviceData))
      : undefined;
  };

  /**
   * updates phone Data
   * @param phoneData
   * @param phoneId
   * @returns {Promise<void>}
   */
  updatePhone = async (phoneData, phoneId) => {
    // call api for update existing phone
    const updatePhoneResponse = await updatePhone(phoneData, phoneId);
    try {
      runInAction(() => {
        this.updatePhoneResponse = updatePhoneResponse;
        this.selectedMobile = undefined;
      });
    } catch (error) {
      runInAction(() => {
        this.updatePhoneResponse = error;
      });
    }
    return this.updatePhoneResponse;
  };

  /**
   * delete phone by Id
   * @param phoneId
   * @returns {Promise<void>}
   */
  deletePhone = async phoneId => {
    // call api for delete existing phone
    const deletePhoneResponse = await deletePhone(phoneId);
    try {
      runInAction(() => {
        this.deletePhoneResponse = deletePhoneResponse;
        this.selectedMobile = undefined;
      });
    } catch (error) {
      runInAction(() => {
        this.deletePhoneResponse = error;
      });
    }
    return this.deletePhoneResponse;
  };

  getPhoneHistory = async (testCaseId, serialNumber) => {
    const getPhoneHistoryResponse = await getPhoneHistory(
      testCaseId,
      serialNumber
    );
    try {
      runInAction(() => {
        this.phoneHistoryResponse = getPhoneHistoryResponse;
      });
    } catch (error) {
      runInAction(() => {
        this.phoneHistoryResponse = error;
      });
    }
    return this.phoneHistoryResponse;
  };

  /**
   * Update Phone Detail
   * @param phoneDetail
   */
  updateRealTimeMobileDetail = phoneDetail => {
    try {
      runInAction(() => {
        const position = _.findIndex(this.mobileList, { _id: phoneDetail._id });
        if (position >= 0) {
          this.mobileList[position] = phoneDetail;
        } else {
          this.mobileList = [...this.mobileList, phoneDetail];
        }
      });
    } catch (error) {}
    return this.mobileList;
  };
}

decorate(ObservablePhoneStore, {
  mobileList: observable,
  selectedMobile: observable,
  updatePhoneResponse: observable,
  deletePhoneResponse: observable,
  phoneHistoryResponse: observable
});

export default new ObservablePhoneStore();
