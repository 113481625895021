import React, { useEffect, useState, useCallback, useMemo, useRef } from "react";
import { inject, observer } from "mobx-react";
import { getFormatedContact, validAusNumberRegExpDeluxe } from "constant/messageConstants";
import { toJS } from "mobx";
import { debounceTime } from "constant/constant";
import debounce from "lodash.debounce";
import FormInput from "components/FormItems/FormInput";
import { List, Popover, Tag } from "antd";
import { isEmpty } from "lodash";

const ContactSelector = ({
  contactStore,
  size = "default",
  label,
  containerProps,
  name,
  mode = "tags",
  placeholder = "Enter mobile numbers",
  errors = {},
  touched = {},
  values = {},
  onChange,
  secondary,
  onDeselect,
  setFieldValue,
  modalStore,
  isTestSms = false,
  containerClassname = "",
  inputClassname = "",
  inputStyle = {},
  filterContactBy = name,
  contactDataField = "numberList"
}) => {
  const inputRef = useRef();

  const { getContactBySearch, setSearchContacts, searchedContact } = contactStore;
  const [searchValue, setSearchValue] = useState("");
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState("");
  const [tempValues, setTempValues] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  const debouncedSave = useCallback(
    debounce(nextValue => setSearchValue(nextValue), debounceTime),
    []
  );

  useEffect(() => {
    const handleClickOutside = event => {
      if (!((event.target.id !== "popover-select-menu") ^ (event.target.id !== "popover-select-item"))) {
        setVisible(false);
        // setValue("");
      }
    };
    if (inputRef.current?.focus) inputRef.current?.focus();
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useMemo(() => {
    if (isTestSms) return;
    if (isEmpty(values[name])) {
      setTempValues([]);
    } else {
      const numberList = _.uniqBy(searchedContact, "value").filter(i => {
        if (values[filterContactBy].includes(i?.value) || values[name].includes(i?.value)) {
          return toJS(i);
        }
      });
      setFieldValue(contactDataField, numberList);
    }
  }, [values?.[name]]);

  useMemo(() => {
    if (isEmpty(value)) setVisible(false);
  }, [value]);

  useEffect(() => {
    if (searchValue) {
      setLoading(true);
      getContactBySearch({ search: searchValue })
        .then(({ data }) => {
          let temp = [];
          if (data.data && data.data.length > 0) {
            temp = data.data.map(i => {
              if (i) {
                return {
                  label: `${i.name} - ${getFormatedContact(i.number)}`,
                  value: getFormatedContact(i.number),
                  number: getFormatedContact(i.number),
                  contactData: {
                    first_name: i.fields?.[0]?.value || "",
                    last_name: i.fields?.[1]?.value || ""
                  }
                };
              }
            });
          }
          if (!isTestSms) {
            setSearchContacts(
              temp && temp.length > 0
                ? temp
                : [
                    {
                      label: getFormatedContact(searchValue),
                      value: getFormatedContact(searchValue)
                    }
                  ]
            );
          }

          temp.splice(0, 0, {
            label: searchValue,
            value: searchValue
          });
          setOptions(temp);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [searchValue]);

  useEffect(() => {
    if (values[name] && values[name].length > 0) {
      setTempValues(
        values[name].map(i => {
          if (i) {
            return {
              label: getFormatedContact(i),
              value: getFormatedContact(i)
            };
          }
        })
      );
    }
  }, [values[name], modalStore.quickSMSContact]);

  const onPressEnter = event => {
    event.stopPropagation();
    event.preventDefault();

    if (isEmpty(options)) return;
    for (let i = 0; i < options.length; i++) {
      if (options[i]?.value) {
        const value = getFormatedContact(options[i].value);
        const label = options[i].label;
        if (value.match(validAusNumberRegExpDeluxe)) {
          if (!isEmpty(values[name]) && values[name]?.includes(value)) return;
          setFieldValue(name, [...values[name], value]);
          let tempItem = {
            label: i === 0 ? value : label,
            value: value
          };
          setTempValues([...tempValues, tempItem]);
          setVisible(false);
          setValue("");
          break;
        } else {
          setVisible(false);
          setValue("");
        }
      }
    }
    return false;
  };

  return (
    <>
      <Popover
        placement="bottomLeft"
        visible={visible}
        id="popover-select-menu"
        autoAdjustOverflow
        getPopupContainer={triggerNode => triggerNode.parentNode}
        content={
          <List
            size="small"
            style={{
              minWidth: "300px",
              overflowY: "scroll",
              maxHeight: "300px"
            }}
            loading={loading}
            dataSource={options}
            renderItem={(item, i) => (
              <List.Item
                onClick={() => {
                  if (item.value.match(validAusNumberRegExpDeluxe)) {
                    const value = getFormatedContact(item.value);
                    if (!isEmpty(values[name]) && values[name]?.includes(value)) return;
                    setFieldValue(name, [...values[name], value]);
                    let tempItem = {
                      label: i === 0 ? value : item.label,
                      value: value
                    };
                    setTempValues([...tempValues, tempItem]);
                    setVisible(false);
                    setValue("");
                  } else {
                    setVisible(false);
                    setValue("");
                  }
                }}
                id="popover-select-item"
                className="pe-auto"
                type="button"
              >
                {item.label}
              </List.Item>
            )}
          />
        }
        trigger={["click", "hover"]}
      >
        <FormInput
          containerClassname={`mb-2 ${containerClassname}`}
          inputClassname={inputClassname}
          size={size}
          label={label}
          name={name}
          style={inputStyle}
          placeholder={placeholder}
          error={errors[name]}
          touched={touched[name]}
          value={value}
          onChange={e => {
            setVisible(true);
            setValue(e.target.value);
            debouncedSave(e.target.value);
          }}
          inputProps={{
            ref: inputRef,
            autoComplete: "off",
            onPressEnter
          }}
          secondary={secondary}
        />
      </Popover>
      <div className="d-flex gap-2 align-items-center flex-wrap">
        {tempValues.map(item => {
          if (item) {
            return (
              <Tag
                key={item?.value}
                closable
                onClose={() => {
                  setFieldValue(
                    name,
                    values[name].filter(i => i !== item.value)
                  );
                  setTempValues(tempValues.filter(i => i.value !== item.value));
                }}
              >
                {item.label}
              </Tag>
            );
          }
        })}
      </div>
    </>
  );
};

export default inject(stores => ({
  contactStore: stores.store.contactStore,
  modalStore: stores.store.modalStore
}))(observer(ContactSelector));
