import { get, post, put } from "utils/api";
import queryString from "query-string";

/**
 * Add new bulk api
 * @param bulkData
 * @returns {Promise<any>}
 */
//MMS
export const addGatewayMMS = async (data = {}) => {
  const addGatewayMMSResponse = await post(`/gatewaymms`, data, true, {
    "Content-Type": `multipart/form-data`
  });
  return addGatewayMMSResponse.data;
};

export const getAllGatewayMMS = async params => {
  const allGatewayMMS = await get("/gatewaymms", params);
  return allGatewayMMS.data.data;
  return [];
};

// MMS Bulk
export const addGatewayMMSBulk = async (data = {}) => {
  const addGatewayMMSBulkResponse = await post("/gatewaymms/bulk", data, true, {
    "Content-Type": "multipart/form-data"
  });
  return addGatewayMMSBulkResponse.data;
};

export const addGatewayMMSBulkByList = async (data = {}) => {
  const addGatewayMMSBulkResponse = await post(
    "/gatewaymms/bulk-number-list",
    data,
    true,
    {
      "Content-Type": "multipart/form-data"
    }
  );
  return addGatewayMMSBulkResponse.data;
};

export const getAllGatewayMMSBulk = async params => {
  // save api response for Bulk List list
  const gatewayListResponse = await get("/gatewaymms/bulk", params);
  return gatewayListResponse.data.data;
};

// Rename Bulk
export const renameBulk = async (Id, name) => {
  const changeBulkName = await put(`/gatewaymms/updatebulk/${Id}`, name);
  return changeBulkName.data;
};

// Export Report
export const getExportBulkLogList = async params => {
  const exportBulkLogListResponse = await get(`/gatewaymms/export`, params);
  return exportBulkLogListResponse.data;
};

export const getBulkDetailsById = async id => {
  const getGatewayBulkDetailsResponse = await get(`/gatewaymms/bulk/${id}`);
  return getGatewayBulkDetailsResponse.data.data;
};

// get bulk short url analytics by id api
export const getBulkShortUrlAnalytics = async (id = "", params) => {
  const BulkResponse = await get(`/gatewaymms/analytic/${id}`, params);
  return BulkResponse.data;
};

// get bulk messages details by id api
export const getBulkMessages = async (id = "", params) => {
  const BulkResponse = await get(`/gatewaymms/campaign/${id}`, params);
  return BulkResponse.data;
};

/**
 * Call get gateway mms stats api for admin
 * @returns {Promise<any>}
 */
export const getGatewayMmsStats = async params => {
  const gatewayMessagesStatsResponse = await get(
    `/admin/gatewaymms/stats`,
    params
  );
  return gatewayMessagesStatsResponse.data;
};

/**
 * Call get filtered gateway mms api for admin
 * @returns {Promise<any>}
 */
export const getFilteredGatewayMMS = async params => {
  const filteredGatewayMessagesResponse = await get(
    `/admin/gatewaymms/filter?`,
    params
  );
  return filteredGatewayMessagesResponse.data;
};
