import React from "react";

const Offline = () => {
  return (
    <div
      className="container-fluid vh-center flex-column mb-3
    "
    >
      <div className="bg-offline d-flex justify-content-center align-items-center"></div>
      <div className="text-center">
        <p className="fw-bold h3">No Internet Connection</p>
        <div className="text-start mb-5">
          <i className="bx bxs-check-circle align-middle me-2 h5 m-0 text-secondary"></i>
          Check your modem or router
          <br />
          <i className="bx bxs-check-circle align-middle me-2 h5 m-0 text-secondary"></i>
          Reconnet to Wi-Fi
        </div>
      </div>
    </div>
  );
};

export default Offline;
