import { runInAction, decorate, observable } from "mobx";

import * as mobx from "mobx";
import {
  getDedicatedNumList,
  getPurchasedDedicatedNumList,
  purchaseDedicatedNum,
  releaseDedicatedNumber
} from "service/dedicatedNumber";

class ObservableDedicatedNoStore {
  numbersList = [];
  purchasedNum = [];
  dedicatedNumResponse = undefined;
  selectedDedicatedNum = undefined;
  pageSize = 10;
  current = 1;
  total = 0;
  totalPages = 0;
  search = "";
  sortField = "createdAt";
  sortOrder = "descend";
  numType = "";

  setSearch = search => {
    runInAction(() => {
      this.search = search;
      this.current = 1;
    });
  };

  setPage = current => {
    runInAction(() => {
      this.current = current;
    });
  };

  setPageSize = pageSize => {
    runInAction(() => {
      this.pageSize = pageSize;
      this.current = 1;
    });
  };

  selectDedicatedNum = paramData => {
    this.selectedDedicatedNum = paramData
      ? JSON.parse(JSON.stringify(paramData))
      : undefined;
  };

  setNumType = type => {
    runInAction(() => {
      this.numType = type;
      this.current = 1;
    });
  };

  getDedicatedNumList = async () => {
    const params = {
      page: this.current,
      sortField: this.sortField,
      sortOrder: this.sortOrder,
      limit: this.pageSize,
      status: false,
      type: this.numType
    };
    if (this.search) params.search = this.search;
    const dedicatedNumResponse = await getDedicatedNumList(params);
    try {
      runInAction(() => {
        const { items, total, current, limit: pageSize } = dedicatedNumResponse;
        this.numbersList = items;
        this.pageSize = pageSize;
        this.current = current;
        this.total = total;
      });
    } catch (error) {
      runInAction(() => {
        this.numbersList = error;
      });
    }
    return this.numbersList;
  };

  getPurchasedDedicatedNumList = async activeParam => {
    const params = {
      ...(activeParam && { active: activeParam })
    };
    const dedicatedNumResponse = await getPurchasedDedicatedNumList(params);
    try {
      runInAction(() => {
        this.purchasedNum = dedicatedNumResponse.map(item => {
          return {
            ...item,
            senderType: "Dedicated Number"
          };
        });
      });
    } catch (error) {
      runInAction(() => {
        this.purchasedNum = error;
      });
    }
    return this.purchasedNum;
  };

  releaseDedicatedNumber = async id => {
    const response = await releaseDedicatedNumber(id);
    try {
      runInAction(() => {
        this.dedicatedNumResponse = response;
        if (response.status) {
          this.purchasedNum = this.purchasedNum.filter(item => item._id !== id);
        }
      });
    } catch (error) {
      runInAction(() => {
        this.dedicatedNumResponse = error;
      });
    }
    return this.dedicatedNumResponse;
  };

  purchaseDedicatedNum = async id => {
    const response = await purchaseDedicatedNum(id);
    try {
      runInAction(() => {
        this.dedicatedNumResponse = response;
        this.getPurchasedDedicatedNumList();
        this.getDedicatedNumList();
      });
    } catch (error) {
      runInAction(() => {
        this.dedicatedNumResponse = error;
      });
    }
    return this.dedicatedNumResponse;
  };
}

decorate(ObservableDedicatedNoStore, {
  total: observable,
  search: observable,
  numType: observable,
  current: observable,
  pageSize: observable,
  sortOrder: observable,
  sortField: observable,
  totalPages: observable,
  purchasedNum: observable,
  selectedDedicatedNum: observable
});

export default new ObservableDedicatedNoStore();
