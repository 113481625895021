import React, { useState, useRef } from "react";
import { observer, inject } from "mobx-react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Modal, Row, Form, Col } from "antd";
import AppButton from "components/AppButton/AppButton";
import styles from "assets/css/modals.module.css";
import UploadFile from "components/UploadFile/UploadFile";
import AlertModal from "components/Modals/AlertModal";
import { fileSupportList, imageSupportList, videoSupportList, audioSupportList } from "common/utils/fileSupportList";
import { FilePdfOutlined, AudioOutlined, VideoCameraOutlined, FileImageOutlined, LoadingOutlined } from "@ant-design/icons";
import View from "components/MMSEditor/View";
import { MAX_FILE_SIZE, MAX_IMG_HEIGHT, MAX_IMG_VDO_SIZE, MAX_IMG_WIDTH, MAX_VDO_HEIGHT, MAX_VDO_WIDTH } from "constant/constant";
import { imageResizer, pdfToImage } from "constant/documentModifiers";
const validationSchema = Yup.object().shape({
  media: Yup.mixed(),
  fileName: Yup.string().required("File is required")
});

// to quickly set initial values of editing states in handleBeforeUpload function
const initialEditingState = {
  pdf: false,
  image: false,
  video: false,
  msg: "",
  type: ""
};

const AttachFile = props => {
  const { modalStore, handleSubmit, gatewayMmsStore, acceptFileType } = props;
  const { setMmsMedia, setSubmit } = gatewayMmsStore;
  const formikRef = useRef();
  const [fileSizeAlert, setFileSizeAlert] = useState(false);
  const [fileSupportAlert, setFileSupportAlert] = useState(false);
  const [editing, setEditing] = useState(initialEditingState);

  const onFileUpload = (e, setFieldValue) => {
    if (e.file && !e.file.type.includes("pdf")) {
      setFieldValue("fileName", e.file.name);
      setFieldValue("type", e.file.type);
    }
  };

  const DANGEROUS_EXTENSIONS = [".exe", ".dll", ".bat", ".cmd", ".msi", ".js", ".vbs"];
  const MAX_FILE_SIZE = 5 * 1024 * 1024;

  const handleBeforeUpload = async (e, setFieldValue) => {
    try {
      // 1. Check file extension
      const fileName = e.name.toLowerCase();
      const fileExtension = "." + fileName.split(".").pop();

      // Block dangerous extensions
      if (DANGEROUS_EXTENSIONS.includes(fileExtension)) {
        setFileSupportAlert(true);
        return false;
      }

      // 2. Check file size
      if (e.size > MAX_FILE_SIZE) {
        setFileSizeAlert(true);
        return false;
      }

      // 3. Check file type support
      if (!fileSupportList.includes(e.type)) {
        setFileSupportAlert(true);
        return false;
      }

      setEditing(initialEditingState);

      // 4. Additional security check for PDFs
      if (e.type.includes("pdf")) {
        try {
          const img = await pdfToImage(e);
          onFileUpload(img, setFieldValue);
          setFieldValue("media", img);
          setFieldValue("fileName", img.name);
          setFieldValue("type", img.type);
          return false;
        } catch (error) {
          console.error("PDF processing error:", error);
          setFileSupportAlert(true);
          return false;
        }
      }

      // 5. Image specific checks
      if (e.type.includes("image")) {
        // Size check for images
        if (e.size > MAX_IMG_VDO_SIZE) {
          setEditing({
            ...editing,
            video: false,
            image: true,
            msg: `Your ${e.type} size is more than 1mb, need to Compress`,
            type: "img/compress"
          });
          setFieldValue("media", e);
          return false;
        }

        // Dimension check
        const img = new Image();
        img.src = URL.createObjectURL(e);
        img.onload = () => {
          if (img.width > MAX_IMG_WIDTH || img.height > MAX_IMG_HEIGHT) {
            setEditing({
              ...editing,
              video: false,
              image: true,
              msg: "Your Image dimensions is more than 1080px * 1920px, need to Crop",
              type: "img/crop"
            });
            setFieldValue("media", e);
            return false;
          }
        };
        img.onerror = () => {
          setFileSupportAlert(true);
          return false;
        };
      }

      setFieldValue("media", e);
      return false;
    } catch (error) {
      console.error("File upload error:", error);
      setFileSupportAlert(true);
      return false;
    }
  };

  // label for submit
  const getLabel = isSubmitting => {
    if (editing.type == "img/compress") {
      return isSubmitting ? "Compressing..." : "Compress";
    } else if (editing.image) {
      return "Crop";
    } else if (editing.video) {
      return "Trim";
    } else if (!editing.image && !editing.video) {
      return "Add";
    }
  };

  return (
    <>
      <View />
      <Modal
        destroyOnClose
        title={
          <div className="text-center">
            <h4 className="pt-3 fw-bold">Attach File</h4>
          </div>
        }
        closeIcon={
          <div className={`btn ${styles.modalCloseBtn}`}>
            <i className="fas fa-times"></i>
          </div>
        }
        visible={modalStore.showAttachFileModal}
        onCancel={() => {
          modalStore.toggleModal("showAttachFileModal", false);
        }}
        maxWidth="850px"
        footer={null}
        centered
        maskStyle={{
          background: "linear-gradient(135deg, #00000050, #107c9850)"
        }}
        zIndex={1033}
      >
        <div className={"px-5"}>
          <Formik
            initialValues={{
              media: null,
              fileName: "",
              type: ""
            }}
            validationSchema={validationSchema}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              if (editing.type == "img/compress") {
                const img = await imageResizer(values.media);
                handleSubmit({ ...values, ...(img && { media: img }) });
              } else if (editing.image && values.type.includes("image")) {
                setMmsMedia(values);
                setSubmit(handleSubmit);
                modalStore.toggleModal("showImageCropModal", true);
              } else if (editing.video && values.type.includes("video")) {
                setMmsMedia(values);
                setSubmit(handleSubmit);
                modalStore.toggleModal("showVideoTrimmerModal", true);
              } else {
                handleSubmit(values);
                resetForm();
              }
              setEditing(initialEditingState);
              setSubmitting(false);
              modalStore.toggleModal("showAttachFileModal", false);
            }}
            innerRef={formikRef}
          >
            {({ handleSubmit, setFieldValue, isSubmitting, errors, touched, values }) => {
              return (
                <Form onSubmit={handleSubmit} className="text-center">
                  <Row type="flex" justify="center" gutter={[0, 20]}>
                    {values.fileName ? (
                      <>
                        <Col span={24} className="text-end">
                          <i
                            className="text-danger-color fas fa-trash-alt position-absolute top-0 end-0 m-2"
                            type="button"
                            onClick={() => {
                              setFieldValue("fileName", "");
                              setFieldValue("media", null);
                              setFieldValue("type", "");
                              setEditing({
                                ...editing,
                                msg: "",
                                type: "",
                                video: false,
                                image: false
                              });
                            }}
                          />
                        </Col>
                        <Col span={24} className="my-4">
                          {imageSupportList.includes(values.type) ? (
                            <>
                              <div style={{ width: "100px", height: "100px" }} className="rounded-circle bg-light d-flex align-items-center justify-content-center mx-auto">
                                <FileImageOutlined className="fs-1 text-info-color" />
                              </div>
                              <span className="text-info-color fs-6 my-4">{values?.fileName}</span>
                            </>
                          ) : videoSupportList.includes(values.type) ? (
                            <>
                              <div style={{ width: "100px", height: "100px" }} className="rounded-circle bg-light d-flex align-items-center justify-content-center mx-auto">
                                <VideoCameraOutlined className="fs-1 text-info-color" />
                              </div>
                              <span className="text-info-color fs-6 my-4">{values?.fileName}</span>
                            </>
                          ) : audioSupportList.includes(values.type) ? (
                            <>
                              <div style={{ width: "100px", height: "100px" }} className="rounded-circle bg-light d-flex align-items-center justify-content-center mx-auto">
                                <AudioOutlined className="fs-1 text-info-color" />
                              </div>
                              <span className="text-info-color fs-6 my-4">{values?.fileName}</span>
                            </>
                          ) : (
                            <>
                              <div style={{ width: "100px", height: "100px" }} className="rounded-circle bg-light d-flex align-items-center justify-content-center mx-auto">
                                <FilePdfOutlined className="fs-1 text-info-color" />
                              </div>
                              <span className="text-info-color fs-6 my-4">{values?.fileName}</span>
                            </>
                          )}
                        </Col>
                      </>
                    ) : (
                      <Col span={24}>
                        <Form.Item name="fileName" validateStatus={errors.fileName && touched.fileName ? "error" : ""} help={touched.fileName && errors.fileName ? errors.fileName : ""}>
                          <UploadFile
                            label="Click or drag and drop to upload file"
                            accept={acceptFileType || "image/*, video/*,.pdf,audio/*"}
                            beforeUpload={e => handleBeforeUpload(e, setFieldValue)}
                            fileList={false}
                            onChange={e => onFileUpload(e, setFieldValue)}
                          />
                        </Form.Item>
                      </Col>
                    )}
                    <Col span={24}>
                      <p className="my-3 text-danger text-center">{editing.msg}</p>
                      <div className="w-100 mb-md-0 my-4 d-flex justify-content-evenly">
                        <AppButton label={getLabel(isSubmitting)} light className="px-3" type="submit" />
                      </div>
                    </Col>
                  </Row>
                </Form>
              );
            }}
          </Formik>
        </div>
      </Modal>
      <AlertModal
        visible={fileSupportAlert}
        title="File not supported"
        description=".jpg, .jpeg, .png, .gif, .mp4, .mkv, .mp3, .pdf. type files allowed"
        primaryBtnProps={{
          onClick: () => {
            setFileSupportAlert(false);
            formikRef.current.setFieldValue("fileName", "");
          },
          label: "Ok"
        }}
        closable
        onCancel={() => {
          setFileSupportAlert(false);
          formikRef.current.setFieldValue("fileName", "");
        }}
        error
      />
      <AlertModal
        visible={fileSizeAlert}
        title="File size to large"
        description="Selected file size is to large, file size must be less than 5MB."
        primaryBtnProps={{
          onClick: () => {
            setFileSizeAlert(false);
            formikRef.current.setFieldValue("fileName", "");
          },
          label: "Ok"
        }}
        closable
        onCancel={() => {
          setFileSizeAlert(false);
          formikRef.current.setFieldValue("fileName", "");
        }}
        error
      />
    </>
  );
};

export default inject(stores => ({
  modalStore: stores.store.modalStore,
  gatewayMmsStore: stores.store.gatewayMmsStore,
  userStore: stores.store.userStore
}))(observer(AttachFile));
