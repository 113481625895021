import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Modal } from "antd";
import styles from "assets/css/modals.module.css";
import MergeLabelsForm from "../GroupForms/MergeLabelsForm";
import { inject, observer } from "mobx-react";
import AlertModal from "components/Modals/AlertModal";

const MergeLabel = props => {
  const { groupStore, modalStore } = props;
  const { group, getAllGroupList, setGroupDataPage, setGroupDataSearch, setGroupDataPageSize } = groupStore;
  const history = useHistory();
  const [alert, setAlert] = useState(false);
  const [groupId, setGroupId] = useState(null);
  const [successAlert, setSuccessAlert] = useState(false);

  return (
    <>
      <Modal
        destroyOnClose
        title={
          <div className="text-center">
            <h4 className="pt-3 fw-bold">Merge Labels</h4>
            <p className={`mb-0 ${styles.modalDes}`}>Manage your merge labels</p>
          </div>
        }
        closeIcon={
          <div className={`btn ${styles.modalCloseBtn}`}>
            <i className="fas fa-times"></i>
          </div>
        }
        visible={modalStore.showMergeLabelModal}
        onCancel={() => {
          modalStore.toggleModal("showMergeLabelModal", false);
          groupStore.setGroupData(null);
        }}
        width={750}
        footer={null}
        centered
        maskStyle={{
          background: "linear-gradient(135deg, #00000050, #107c9850)"
        }}
      >
        <MergeLabelsForm groupId={groupId} setAlert={setAlert} setGroupId={setGroupId} successAlert={successAlert} setSuccessAlert={setSuccessAlert} />
      </Modal>
      <AlertModal
        visible={successAlert}
        title="File uploaded"
        description="Contacts have been successfully added to the queue. Will be added to the group shortly."
        secondaryBtn={!group}
        primaryBtnProps={{
          label: group ? "Ok" : "Go to Group",
          onClick: () => {
            setSuccessAlert(false);
            if (!group) {
              history.push(`/group/${groupId}`);
              setGroupDataPage(1);
              setGroupDataPageSize(10);
              setGroupDataSearch("");
            }
          }
        }}
        secondaryBtnProps={{
          label: "Ok",
          onClick: () => {
            setSuccessAlert(false);
            getAllGroupList();
          }
        }}
      />
      <AlertModal
        title="File problem"
        description="There is a problem with this file. Please try with another file."
        visible={alert}
        error
        primaryBtnProps={{
          label: "Ok",
          onClick: () => {
            setAlert(false);
            modalStore.toggleModal("showMergeLabelModal", false);
          }
        }}
      />
    </>
  );
};

export default inject(stores => ({
  groupStore: stores.store.groupStore,
  modalStore: stores.store.modalStore
}))(observer(MergeLabel));
