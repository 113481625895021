import { sharedNumber } from "./constant";

export const englishCharArr = JSON.parse(process.env.REACT_ENGLISH_GATEWAY_SMS_CONFIG);

export const unicodeCharArr = JSON.parse(process.env.REACT_UNICODE_GATEWAY_SMS_CONFIG);

export const resendCounterTime = 180;

export const langRegEx = /^[\x00-\x7F ’]+$/;

export const optOutMessageSample = process.env.REACT_OPT_OUT_LINK_SAMPLE;

export const replyStopMessageSample = "Reply STOP to opt-out";

export const replyLinkMessageSample = process.env.REACT_REPLY_LINK_SAMPLE;

export const mobileRegExp = /^[0-9]{11}$/;

export const countryCodeRegExp = /^61/;

export const fieldRegEx = /{{[a-z- ]*}}/gi;

export const contactFieldSeperatorRegEx = /[,;]/;

export const validAusNumberRegExp = /^(\+?\(61\)|\(\+?61\)|\+?61|\(0[1-9]\)|0[1-9])?( ?-?[0-9]){7,9}$/gm;

export const shortUrlSample = process.env.REACT_SHORT_URL_SAMPLE;

export const validAusNumberRegExpDeluxe = /^(?:\+?(61))? ?(?:\((?=.*\)))?(0?[4])\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$/gm;

export const emailPhoneRegExp = /^(?:\+?(61))? ?(?:\((?=.*\)))?(0?[4])\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$|^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/gm;

export const emailRegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/gm;

export const contactMaskRegExp = /^(.{3})[^@]+(.{3})/;

export const urlRegExp = /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;

export const googleSheetRegExp = /[(http|https)://]?docs.google.com\/spreadsheets\/d\/[a-zA-Z0-9/_/-]+\//;

export const activeCampUrlRegExp = /^https:\/\/([^.]+)\.activehosted\.com/;

export function getFormatedContact(contact) {
  try {
    // remove any symbols in  mobile number
    let mobile = contact.replace(/[^0-9]/g, "");
    // remove any spaces in mobile number
    mobile = mobile.replace(/\s/g, "");
    // remove any leading 0 in mobile number
    mobile = mobile.replace(/^0+/, "");
    // add country code to mobile number
    switch (true) {
      case mobile.length === 9:
        return "61" + mobile;
      default:
        return mobile;
    }
  } catch (err) {
    console.log("contact -> ", contact);
    console.log(err);
    // return '';
  }
}

export function getParsedContact(contact, removeCountryCode = false) {
  if (contact.match(emailRegExp)) return contact;
  let mobile = contact.split(" ").join("");
  mobile = mobile.split("-").join("");
  mobile = mobile.split("+").join("");
  switch (true) {
    case mobile.length === 10:
      if (removeCountryCode) return mobile.split(/0(.+)/)[1];
      return "61" + mobile.split(/0(.+)/)[1];
    case mobile.length === 11:
      if (removeCountryCode) return mobile.split(/61(.+)/)[1];
      return "61" + mobile.split(/61(.+)/)[1];
    case mobile.length === 12:
      if (removeCountryCode) return mobile.split(/610(.+)/)[1];
      return "61" + mobile.split(/610(.+)/)[1];
    default:
      if (removeCountryCode) return mobile;
      return "61" + mobile;
  }
}

export function getFormatedContacts(contacts) {
  return contacts.map(mobileUnformatted => {
    // remove any symbols in  mobile number
    let mobile = mobileUnformatted.replace(/[^0-9]/g, "");
    // remove any spaces in mobile number
    mobile = mobile.replace(/\s/g, "");
    // remove any leading 0 in mobile number
    mobile = mobile.replace(/^0+/, "");
    // add country code to mobile number
    switch (true) {
      case mobile.length === 9:
        return "61" + mobile;
      default:
        return mobile;
    }
  });
}

export function checkSender(value = "", user, messageStore) {
  if (
    user &&
    ((user?.mobileSenders && user?.mobileSenders.includes(value)) || (user?.senders && user?.senders.includes(value)) || user?.mobile === value || user?.countryCode + user?.mobile === value)
  ) {
    return value;
  } else {
    return sharedNumber;
  }
}

export function checkEmailOrPhone(value) {
  function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  function validateOnlyNumbers(phone) {
    return phone.match(/^\d+$/);
  }

  if (validateOnlyNumbers(value)) {
    return { mobile: value };
  }
  if (validateEmail(value)) {
    return { email: value };
  }
  if (!validateOnlyNumbers(value) && !validateEmail(value)) {
    return value;
  }
}

export function messageCounts(msgs, values) {
  let total = 0;
  let unsubscribe = 0;
  let subscribe = 0;

  if (Array.isArray(msgs)) {
    total = msgs?.reduce((acc, obj) => acc + parseInt(obj?.totalContacts || "0"), 0);
    unsubscribe = msgs?.reduce((acc, obj) => acc + obj?.unsubscribe, 0);
  } else {
    total = msgs?.totalContacts;
    unsubscribe = msgs?.unsubscribe;
  }

  if (values?.numberList) {
    total = values?.numberList?.length;
  }

  if (values?.contacts) {
    total = values?.contacts?.length;
    unsubscribe = 0;
  }

  subscribe = total - unsubscribe;

  return { subscribe, unsubscribe, total };
}
