export const AUTH_TOKEN = "authToken";
export const REFRESH_TOKEN = "refreshToken";
export const AUTH_USER = "authUser";
export const ROLE_TOKEN = "roletoken";
export const ADMIN_TOKEN = "adminToken";

export const AUTH_USER_INFO = "info";
export const AUTH_USER_BALANCE = "balance";

export const guniHelpUrlOld = "https://help.gunisms.com.au";
export const guniHelpUrl = "https://support.gunisms.com.au/knowledge-base";

export const dateFormat = "DD/MM/YYYY";
export const timeFormat = "hh:mm A";
export const dateTimeFormat = "DD/MM/YYYY hh:mm A";
export const dateTimeFormatWithSeconds = "DD/MM/YYYY hh:mm:ss A";
export const deluxDateTimeFormat = "MMM DD, YYYY hh:mm A";
export const dateTimeConnectedFormat = "DD-MM-YY_HH:mm";

export const expireTime = 30 * 24 * 3600;
export const debounceTime = 500;

export const MAX_IMG_VDO_SIZE = 1048576;
export const MAX_FILE_SIZE = 5242880;
export const MAX_IMG_WIDTH = 1080;
export const MAX_IMG_HEIGHT = 1920;
export const MAX_VDO_WIDTH = 1280;
export const MAX_VDO_HEIGHT = 1138;

export const sharedNumber = "#SharedNum#";
export const subjectMaxLength = 40;

export const maximunActions = 8;

export const batchMaxContact = 50000;

// export const multigroupLimit = 100000;
export const multigroupLimit = 3000;
