import { cookie } from "../utils/cookie";
import { AUTH_TOKEN, REFRESH_TOKEN } from "constant/constant";

export const loadState = state => {
  try {
    if (cookie.get(AUTH_TOKEN) && cookie.get(REFRESH_TOKEN)) {
      state.authStore.token = cookie.get(AUTH_TOKEN);
      state.authStore.isAuthenticated = true;
    } else {
      state.authStore.isAuthenticated = false;
    }
    return state;
  } catch (err) {
    return state;
  }
};
