import React, { useState, useEffect } from "react";
import { getCountryListMap } from "country-flags-dial-code";
import FormInput from "components/FormItems/FormInput";
import contactStyles from "./auth.module.css";
import { isEmpty } from "lodash";

function ContactInput(props) {
  const {
    value,
    label = null,
    placeholder = null,
    containerProps = {},
    name,
    type = "text",
    inputClassname = "",
    containerClassname = "",
    onChange,
    error,
    touched
  } = props;

  const [dialCode, setDialCode] = useState("+61");
  const [isNum, setIsNum] = useState(false);
  useEffect(() => {
    if (value) {
      value.match(/^[\d|\-| |+]+$/) ? setIsNum(true) : setIsNum(false);
    } else {
      setIsNum(false);
    }
  }, [value]);

  return (
    <React.Fragment>
      <div className={`${contactStyles.ContactContainer}`}>
        {isNum && (
          <select
            disabled
            className={`${contactStyles.ContactSelectBox} ms-3 bg-transparent rounded border-0 m-2`}
            value={dialCode}
            onChange={e => {
              setDialCode(e.target.value);
              const code = e.target.value;
              onChange(
                value,
                code.includes("+") ? code.replace(/\+/, "") : code
              );
            }}
          >
            <option value="">Select</option>
            {Object.entries(getCountryListMap()).map(([key, item]) => {
              return (
                <option value={item.dialCode} key={key}>
                  {item.code} {item.dialCode}
                </option>
              );
            })}
          </select>
        )}
        <FormInput
          value={value}
          label={label}
          placeholder={placeholder}
          containerProps={containerProps}
          name={name}
          type={type}
          inputClassname={`${
            isNum ? contactStyles.ContactInput : ""
          } ${inputClassname}`}
          containerClassname={containerClassname}
          onChange={e => onChange(e.target.value, dialCode.replace(/\+/, ""))}
          error={error}
          touched={touched}
        />
      </div>
    </React.Fragment>
  );
}

export default ContactInput;
