import { decorate, observable, runInAction } from "mobx";
import { getRooms, setLastSeenRoom, updateRoom } from "service/messageService";
import _ from "lodash";
import * as mobx from "mobx";

class ObservableRoomStore {
  rooms = [];
  pageSize = 10;
  current = 1;
  total = 0;
  sortField = "createdAt";
  sortOrder = "descend";
  search = "";
  selectedRoom = {};

  changeSorting = fieldObj => {
    runInAction(() => {
      const { field, sortOrder } = fieldObj;
      this.sortField = field;
      this.sortOrder = sortOrder;
    });
  };

  setSearch = search => {
    runInAction(() => {
      this.search = search;
    });
  };

  setSelectedRoom = selectedRoom => {
    runInAction(() => {
      this.selectedRoom = selectedRoom;
    });
  };

  setPage = current => {
    runInAction(() => {
      this.current = current || 1;
    });
  };

  /**
   * Start Test Case
   * @returns {Promise<any>}
   */
  getRooms = async isAdd => {
    const params = {
      page: isAdd ? this.current + 1 : this.current
    };

    const roomsResponse = await getRooms(params);
    const { items, total, current, limit: pageSize } = roomsResponse;

    try {
      runInAction(() => {
        this.rooms = isAdd
          ? _.uniq(
              _.concat(mobx.toJS(this.rooms), items || roomsResponse),
              "_id"
            )
          : _.uniq(items || roomsResponse, "_id");
        this.pageSize = pageSize;
        this.current = current;
        this.total = total;
      });
    } catch (error) {
      runInAction(() => {
        this.rooms = error;
      });
    }
    return this.rooms;
  };

  updateRoom = async params => {
    const room = await updateRoom(params);
    try {
      runInAction(() => {
        this.rooms = this.rooms.map(item =>
          item._id === room._id ? room : item
        );
      });
    } catch (error) {
      runInAction(() => {
        this.rooms = error;
      });
    }
    return room;
  };

  setLastSeen = async param => {
    const room = await setLastSeenRoom(param);
    try {
      runInAction(() => {
        this.rooms = this.rooms.map(item =>
          item._id === room._id ? room : item
        );
      });
    } catch (error) {
      runInAction(() => {
        this.rooms = error;
      });
    }
    return room;
  };

  addRoom = async params => {
    try {
      runInAction(() => {
        if (params && params._id) {
          this.rooms = _.sortBy(_.mergeById(this.rooms, params, "_id"), [
            "updatedAt"
          ]).reverse();
        }
      });
    } catch (error) {
      console.error("Error", error);
    }
    return this.rooms;
  };
}

decorate(ObservableRoomStore, {
  rooms: observable,
  selectedRoom: observable,
  conversation: observable,
  pageSize: observable,
  current: observable,
  total: observable,
  sortField: observable,
  sortOrder: observable,
  search: observable
});
export default new ObservableRoomStore();
