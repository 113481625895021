import React from "react";
import * as BrandIcon from "assets/images/cards";

const CardIcon = ({ brand }) => {
  return (
    <img
      alt={brand}
      width={brand === "amex" ? "40px" : "auto"}
      height={40}
      src={
        {
          mastercard: BrandIcon.master,
          visa: BrandIcon.visa,
          amex: BrandIcon.amex,
          discover: BrandIcon.discover,
          jcb: BrandIcon.jcb,
          diners: BrandIcon.diners,
          hiper: BrandIcon.hiper,
          elo: BrandIcon.elo,
          unionpay: BrandIcon.unionpay,
          generic: BrandIcon.generic,
          maestro: BrandIcon.maestro
        }[brand] || BrandIcon.unknown
      }
    />
  );
};

export default CardIcon;
