import { get, post, put, deleteRequest } from "utils/api";

export const getAllCategory = async () => {
  const CategoryListResponse = await get(
    "/category?includeInbuiltCategories=true"
  );
  return CategoryListResponse.data.data;
};

export const addCategory = async (name = {}) => {
  const CreateCategoryResponse = await post("/category", name);
  return CreateCategoryResponse.data;
};

export const deleteCategory = async id => {
  const DeleteCategoryResponse = await deleteRequest(`/category/${id}`);
  return DeleteCategoryResponse.data;
};

export const updateCategory = async (id, name) => {
  const UpdateCategoryResponse = await put(`/category/${id}`, name);
  return UpdateCategoryResponse.data;
};
