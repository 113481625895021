import React from "react";
import { inject, observer } from "mobx-react";
import { Modal } from "antd";
import styles from "assets/css/modals.module.css";
import ContactForm from "../GroupForms/ContactForm";

const QuickContact = props => {
  const { modalStore, contact, setContact } = props;

  return (
    <>
      <Modal
        title={
          <div className="text-center">
            <h5 className="pt-3 fw-bold">
              {contact ? "Update " : "Add Quick "} Contact
            </h5>
          </div>
        }
        closeIcon={
          <div className={`btn ${styles.modalCloseBtn}`} type="button">
            <i className="fas fa-times"></i>
          </div>
        }
        visible={modalStore.showQuickContactModal}
        onCancel={() => {
          modalStore.toggleModal("showQuickContactModal", false);
          if (setContact) setContact(null);
        }}
        width={550}
        footer={null}
        maskStyle={{
          background: "linear-gradient(135deg, #00000050, #107c9850)"
        }}
      >
        <div className="px-5">
          <ContactForm contact={contact} setContact={setContact} />
        </div>
      </Modal>
    </>
  );
};

export default inject(stores => ({
  modalStore: stores.store.modalStore
}))(observer(QuickContact));
