import { post } from "utils/api";

export const verifyMyPhoneOTP = async params => {
  const verifyOTPResponse = await post(
    `/auth/mobile/verifyLoginMobileOTP`,
    params
  );
  return verifyOTPResponse.data;
};

// social signup to complete profile
export const updateProfileSocially = async (values, queryParams) => {
  const addResponse = await post(
    `/user/updatesocialloginAccount?user=${queryParams}`,
    values
  );
  return addResponse.data;
};

// complete profile with email and other info
export const completeProfile = async params => {
  const completeProfileResponse = await post(
    `/user/new-additional-info`,
    params
  );
  return completeProfileResponse.data;
};

//send verification code for completing profile
export const verifyUserEmail = async params => {
  const response = await post(`/user/sendVerification`, params);
  return response.data;
};
