import React, { useState } from "react";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import { Popover } from "antd";
import AppButton from "components/AppButton/AppButton";

const EmojiPicker = props => {
  const { onEmojiSelect, btnProps = {}, size = "large", btnClass = "", placement } = props;
  const [showPopover, setShowPopover] = useState(false);

  const handleClick = () => setShowPopover(!showPopover);
  return (
    <Popover
      content={<Picker data={data} onEmojiSelect={onEmojiSelect} emojiSize={18} theme="light" previewPosition="none" />}
      onVisibleChange={handleClick}
      visible={showPopover}
      trigger="click"
      placement={placement || "topRight"}
      zIndex={1055}
    >
      <AppButton className={`border-0 bg-light ${btnClass}`} prefixIcon={<i className="far fa-smile text-info-color"></i>} onClick={handleClick} size={size} {...btnProps} />
    </Popover>
  );
};

export default EmojiPicker;
