import { decorate, observable, runInAction } from "mobx";
import {
  getSharedNumberList,
  addSharedNumber,
  updateSharedNumber,
  deleteSharedNumber,
  getSharedNumberCountByPool
} from "service/sharedNumberService";

class ObservableSharedNumberStore {
  sharedNumberList = [];
  addSharedNumberResponse = undefined;
  updateSharedNumberResponse = undefined;
  selectedSharedNumber = undefined;
  deleteSharedNumberResponse = undefined;
  sharedNumCount = {
    quick: false,
    bulk: false
  };

  /**
   * get all param
   * @returns {Promise<[]>}
   */
  getAllSharedNumber = async () => {
    const sharedNumberResponse = await getSharedNumberList();
    try {
      runInAction(() => {
        this.sharedNumberList = sharedNumberResponse;
        this.selectedSharedNumber = undefined;
      });
    } catch (error) {
      runInAction(() => {
        this.status = "error";
      });
    }
    return this.sharedNumberList;
  };

  getSharedNumberCountByPool = async pool => {
    const sharedNumberCountResponse = await getSharedNumberCountByPool(pool);
    try {
      runInAction(() => {
        this.sharedNumCount[pool] = sharedNumberCountResponse;
      });
    } catch (error) {
      this.status = "error";
      this.sharedNumCount[pool] = true;
    }
    return this.sharedNumCount;
  };

  /**
   * add new param
   * @returns {Promise<*>}
   * @param paramData
   */
  addSharedNumber = async paramData => {
    const addSharedNumberResponse = await addSharedNumber(paramData);
    try {
      runInAction(() => {
        this.addSharedNumberResponse = addSharedNumberResponse;
        this.getAllSharedNumber();
      });
    } catch (error) {
      runInAction(() => {
        this.addSharedNumberResponse = error;
      });
    }
    return this.addSharedNumberResponse;
  };

  /**
   * select Test Case Data for Update
   * @param paramData
   */
  selectSharedNumber = paramData => {
    this.selectedSharedNumber = paramData
      ? JSON.parse(JSON.stringify(paramData))
      : undefined;
  };

  /**
   * updates Param Data
   * @param paramData
   * @param paramId
   * @returns {Promise<void>}
   */
  updateSharedNumber = async (paramData, paramId) => {
    const updateSharedNumberResponse = await updateSharedNumber(
      paramData,
      paramId
    );
    try {
      runInAction(() => {
        this.updateSharedNumberResponse = updateSharedNumberResponse;
        this.selectedSharedNumber = undefined;
        this.getAllSharedNumber();
      });
    } catch (error) {
      runInAction(() => {
        this.updateSharedNumberResponse = error;
      });
    }
    return this.updateSharedNumberResponse;
  };

  /**
   * Delete Test Case
   * @param paramId
   * @returns {Promise<void>}
   */
  deleteSharedNumber = async paramId => {
    const deleteSharedNumberResponse = await deleteSharedNumber(paramId);
    try {
      runInAction(() => {
        this.deleteSharedNumberResponse = deleteSharedNumberResponse;
        this.getAllSharedNumber();
      });
    } catch (error) {
      runInAction(() => {
        this.deleteSharedNumberResponse = error;
      });
    }
    return this.updateSharedNumberResponse;
  };
}

decorate(ObservableSharedNumberStore, {
  sharedNumberList: observable,
  addSharedNumberResponse: observable,
  updateSharedNumberResponse: observable,
  selectedSharedNumber: observable,
  deleteSharedNumberResponse: observable,
  sharedNumCount: observable
});
export default new ObservableSharedNumberStore();
