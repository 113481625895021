import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { Link, useLocation } from "react-router-dom";
import { Tooltip, Typography } from "antd";
import styles from "./applayout.module.css";
import clsx from "clsx";

const MenuBtn = ({
  label,
  icon,
  path,
  submenu = [],
  onClick,
  activeId,
  itemIndex,
  topMargin,
  subPaths,
  active,
  authStore,
  ...rest
}) => {
  const { user } = authStore;
  const isActive = activeId === itemIndex;
  const location = useLocation();

  return (
    <>
      {active ? (
        <>
          {submenu && submenu.length > 0 ? (
            <>
              <li>
                <div
                  className={`${styles.item} ${
                    submenu.find(o => o.path === location.pathname) ||
                    (subPaths && subPaths.includes(location.pathname)) ||
                    isActive
                      ? styles.active
                      : ""
                  } mx-auto p-1`}
                  onClick={onClick}
                  style={{
                    marginTop: activeId + 1 === itemIndex ? topMargin : ".4rem"
                  }}
                >
                  {icon}{" "}
                  {active && (
                    <Typography.Text
                      className={`${styles.label} d-flex justify-content-between align-items-center`}
                    >
                      {label}
                      <i
                        className={`fas ${
                          isActive ? "fa-angle-down" : "fa-angle-right"
                        }`}
                      />
                    </Typography.Text>
                  )}
                  <ul
                    className={`list-unstyled ${
                      isActive ? styles.active : ""
                    } m-0 ${styles.subList} ${active ? styles.menuWidth : ""}`}
                  >
                    {submenu.map((item, index) => {
                      if (
                        item?.roles.includes(user.role) ||
                        user?.access.some(
                          r =>
                            item?.access &&
                            item?.access.length &&
                            item?.access.includes(r)
                        )
                      ) {
                        return (
                          <Link
                            to={{
                              pathname: item.path,
                              state: { from: location.pathname }
                            }}
                            className={styles.listItemLink}
                            key={index}
                          >
                            <li className={styles.listItem}>{item.label}</li>
                          </Link>
                        );
                      }
                    })}
                  </ul>
                </div>
              </li>
            </>
          ) : (
            <Link
              to={{ pathname: path, state: { from: location.pathname } }}
              onClick={onClick}
              {...rest}
            >
              <li>
                <div
                  className={`${styles.item} ${
                    location.pathname === path ||
                    (subPaths && subPaths.includes(location.pathname))
                      ? styles.active
                      : ""
                  } mx-auto p-1 position-relative`}
                  onClick={onClick}
                  style={{
                    marginTop: activeId + 1 === itemIndex ? topMargin : ".4rem"
                  }}
                >
                  {icon}{" "}
                  {active && (
                    <Typography.Text
                      className={`${styles.label} d-flex justify-content-start align-items-center`}
                    >
                      {label}
                    </Typography.Text>
                  )}
                </div>
              </li>
            </Link>
          )}
        </>
      ) : (
        <>
          {submenu && submenu.length > 0 ? (
            <Tooltip title={label} placement="right">
              <li>
                <div
                  className={`${styles.item} ${styles.small} ${
                    submenu.find(o => o.path === location.pathname) ||
                    (subPaths && subPaths.includes(location.pathname)) ||
                    isActive
                      ? styles.active
                      : ""
                  } mx-auto p-1`}
                  onClick={onClick}
                  style={{
                    marginTop: activeId + 1 === itemIndex ? topMargin : ".4rem"
                  }}
                >
                  {icon}
                  <ul
                    className={`list-unstyled ${
                      isActive ? styles.active : ""
                    } m-0 ${styles.subList} ${active ? styles.menuWidth : ""}`}
                  >
                    {submenu.map((item, index) => {
                      if (
                        item.roles.includes(user.role) ||
                        (item.access &&
                          user.access.some(r => item.access.includes(r)))
                      ) {
                        return (
                          <Link
                            to={{
                              pathname: item.path,
                              state: { from: location.pathname }
                            }}
                            className={styles.listItemLink}
                            key={index}
                          >
                            <li className={styles.listItem}>{item.label}</li>
                          </Link>
                        );
                      }
                    })}
                  </ul>
                </div>
              </li>
            </Tooltip>
          ) : (
            <Tooltip title={label} placement="right">
              <Link
                to={{ pathname: path, state: { from: location.pathname } }}
                onClick={onClick}
              >
                <li>
                  <div
                    className={`${styles.item} ${styles.small} ${
                      location.pathname === path ||
                      (subPaths && subPaths.includes(location.pathname))
                        ? styles.active
                        : ""
                    } mx-auto p-1`}
                    onClick={onClick}
                    style={{
                      marginTop:
                        activeId + 1 === itemIndex ? topMargin : ".4rem"
                    }}
                  >
                    {icon}
                  </div>
                </li>
              </Link>
            </Tooltip>
          )}
        </>
      )}
    </>
  );
};

export default inject(stores => ({
  authStore: stores.store.authStore
}))(observer(MenuBtn));
