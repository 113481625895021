import { get, post, put } from "utils/api";
import queryString from "query-string";

/**
 * Start Test from TestCaseId api
 * @param data
 * @returns {Promise<any>}
 */
export const sendSMS = async (data, mode) => {
  const addResponse = await post(`/message?mode=${mode}`, data);
  return addResponse.data;
};

/**
 * Get Test Result from api
 * @param participants
 * @returns {Promise<any>}
 */
export const getConversation = async params => {
  let participant = queryString.stringify(params, { arrayFormat: "bracket" });
  console.log(participant);
  const conversationResponse = await get(`/message?${participant}`);

  return conversationResponse.data;
};

/**
 * Start Test from TestCaseId api
 * @param data
 * @returns {Promise<any>}
 */
export const getRooms = async params => {
  const roomResponse = await get(`/room`, params);
  return roomResponse.data;
};

export const updateRoom = async params => {
  const roomResponse = await put(`/room/${params._id}`, params);
  return roomResponse.data;
};

export const setLastSeenRoom = async params => {
  const roomResponse = await put(`/room/${params._id}/lastSeen`, params);
  return roomResponse.data;
};
