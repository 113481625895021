import React from "react";
import { Modal, Row, Col, Typography } from "antd";
import AppButton from "components/AppButton/AppButton";
import styles from "assets/css/modals.module.css";
import alert from "assets/images/modal/alert.svg";
import success from "assets/images/modal/success.svg";
import confirm from "assets/images/modal/confirm.png";
import FormInput from "components/FormItems/FormInput";

const AlertModal = ({
  visible,
  onCancel = () => {},
  error = false,
  warning = false,
  title = "Success",
  modalWidth = 415,
  description,
  closable = false,
  btn = true,
  secondaryBtn = false,
  primaryBtnProps = {
    onClick: () => {}
  },
  secondaryBtnProps = {
    onClick: () => {}
  },
  className = "py-0",
  zIndex = 1055,
  loading = false,
  inputProps = {},
  input = false,
  endComponent = null,
  endPropContainerClass = "mt-4",
  descriptionNode = null,
  withoutHeader = false
}) => {
  return (
    <Modal
      centered
      title={
        withoutHeader ? null : (
          <div className="text-center">
            <img src={error ? alert : warning ? confirm : success} alt="alert" style={{ width: "60px", height: "auto" }} />
          </div>
        )
      }
      zIndex={zIndex}
      className={className}
      closeIcon={
        <div className={`btn ` + styles.modalCloseBtn}>
          <i className="fas fa-times"></i>
        </div>
      }
      closable={closable}
      visible={visible}
      onCancel={onCancel}
      width={modalWidth}
      footer={null}
      maskStyle={{
        background: "linear-gradient(135deg, #00000050, #107c9850)"
      }}
      bodyStyle={{
        padding: "0"
      }}
    >
      <Row type="flex" justify="center" className="pb-4">
        {title && (
          <Col span={24} className="text-center">
            <Typography.Text strong className="text-dark fs-5 text-capitalize">
              {title}
            </Typography.Text>
          </Col>
        )}
        {descriptionNode && (
          <Col span={20} className="text-center">
            <Typography.Text className="m-0">{descriptionNode}</Typography.Text>
          </Col>
        )}
        {description && (
          <Col span={20} className="text-center">
            <Typography.Text className="m-0">
              <span dangerouslySetInnerHTML={{ __html: description }} />
            </Typography.Text>
          </Col>
        )}
        {input && (
          <Col span={20} className="text-center">
            <FormInput {...inputProps} containerClassname="mb-0 mt-3" style={{ width: "100%" }} />
          </Col>
        )}
        <Col span={24} className={`d-flex flex-row justify-content-center gap-3 ${endPropContainerClass}`}>
          {secondaryBtn && <AppButton light withoutBg label={secondaryBtnProps.label ? secondaryBtnProps.label : "Cancel"} {...secondaryBtnProps} disabled={loading} />}
          {btn && <AppButton light label={primaryBtnProps.label ? primaryBtnProps.label : "Continue"} {...primaryBtnProps} disabled={loading} />}
          {endComponent && endComponent}
        </Col>
      </Row>
    </Modal>
  );
};

export default AlertModal;
