import { runInAction, decorate, observable } from "mobx";
import {
  deleteVerificationForm,
  getAllForms,
  getAllFormsById,
  sendForVerification,
  submitFormStep,
  uploadDocument,
  getUploadedDocument
} from "service/userVerification";

class ObservableUserVerificationStore {
  userForms = [];
  userData = {};
  fetchedForm = {};
  userType = undefined;
  response = undefined;
  verificationId = undefined;
  pageSize = 10;
  current = 1;
  total = 0;
  totalPages = 0;
  search = "";
  sortField = "createdAt";
  sortOrder = "descend";
  filename = "";

  setSearch = search => {
    runInAction(() => {
      this.search = search;
      this.current = 1;
    });
  };

  setPage = current => {
    runInAction(() => {
      this.current = current;
    });
  };

  setPageSize = pageSize => {
    runInAction(() => {
      this.pageSize = pageSize;
      this.current = 1;
    });
  };

  setUserType = type => {
    runInAction(() => {
      type ? (this.userType = type) : (this.userType = undefined);
    });
  };

  setFormData = data => {
    runInAction(() => {
      data ? (this.fetchedForm = data) : (this.fetchedForm = {});
    });
  };

  setVerificationId = id => {
    runInAction(() => {
      id ? (this.verificationId = id) : (this.verificationId = undefined);
    });
  };

  setUserData = (key, data) => {
    runInAction(() => {
      data
        ? (this.userData = {
            ...this.userData,
            [key]: data
          })
        : (this.userData = {});
    });
  };

  submitFormStep = async (data, step) => {
    const res = await submitFormStep(data, step);
    try {
      runInAction(() => {
        this.response = res;
      });
    } catch (error) {
      console.log(error);
      this.response = error;
    }
    return this.response;
  };

  getAllForms = async () => {
    const params = {
      page: this.current,
      sortField: this.sortField,
      sortOrder: this.sortOrder,
      limit: this.pageSize,
      status: false,
      type: this.numType
    };
    if (this.search) params.search = this.search;
    const res = await getAllForms(params);
    try {
      runInAction(() => {
        const { items, total, current, limit: pageSize } = res;
        this.userForms = items;
        this.pageSize = pageSize;
        this.current = current;
        this.total = total;
      });
    } catch (error) {
      console.log(error);
      this.userForms = error;
    }
    return this.userForms;
  };

  setFilename = filename => {
    runInAction(() => {
      this.filename = filename;
    });
  };

  getFormDataById = async (id, forPreview = false) => {
    const res = await getAllFormsById(id);
    try {
      runInAction(() => {
        if (forPreview) {
          this.userData = res;
          this.filename = res?.step3?.["verificationDoc"]?.[0]?.split(
            "/document/"
          )[1];
        } else {
          this.fetchedForm = res;
        }
      });
    } catch (error) {
      console.log(error);
      forPreview ? (this.userData = error) : (this.fetchedForm = error);
    }
    return forPreview ? this.userData : this.fetchedForm;
  };

  sendForVerification = async (id, data) => {
    const res = await sendForVerification(id, data);
    try {
      runInAction(() => {
        this.response = res;
        this.getAllForms();
      });
    } catch (error) {
      this.response = error;
    }
    return this.response;
  };

  deleteForm = async id => {
    const res = await deleteVerificationForm(id);
    try {
      runInAction(() => {
        this.response = res;
        this.getAllForms();
      });
    } catch (error) {
      this.response = error;
    }
    return this.response;
  };

  uploadDocument = async data => {
    const res = await uploadDocument(data);
    try {
      runInAction(() => {
        this.response = res;
      });
    } catch (error) {
      this.response = error;
    }
    return this.response;
  };

  getUploadedDocument = async name => {
    const res = await getUploadedDocument(name);
    try {
      runInAction(() => {
        this.response = res;
      });
    } catch (error) {
      this.response = error;
    }
    return this.response;
  };
}

decorate(ObservableUserVerificationStore, {
  userData: observable,
  userForms: observable,
  fetchedForm: observable,
  userType: observable,
  verificationId: observable,
  total: observable,
  search: observable,
  current: observable,
  pageSize: observable,
  sortOrder: observable,
  sortField: observable,
  totalPages: observable,
  filename: observable
});

export default new ObservableUserVerificationStore();
