import ProfileLayoutLoader from "components/ProfileLayout/ProfileLayoutLoader";
import React, { useState } from "react";
import { inject, observer } from "mobx-react";
import FormInput from "components/FormItems/FormInput";
import AppButton from "components/AppButton/AppButton";
import EmailOtpModal from "../Modals/EmailOtpModal";
import {
  checkEmailOrPhone,
  getParsedContact,
  validAusNumberRegExpDeluxe
} from "constant/messageConstants";
import { useHistory } from "react-router-dom";
import EmailVerifyForm from "./EmailVerifyForm";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Tabs } from "antd";
import ContactInput from "components/Authentication/Common/ContactInput";
import FloatLabel from "components/Authentication/Common/FloatLabel/FloatLabel";
const { TabPane } = Tabs;

const View = props => {
  const { authStore, modalStore } = props;
  const history = useHistory();
  const [indexKey, setIndexKey] = useState(1);

  if (!authStore.user) {
    return (
      <div className="container-fluid mt-4">
        <ProfileLayoutLoader />
      </div>
    );
  }

  const initialValues = {
    gender: "",
    companyName: "",
    designation: "",
    contact: localStorage.getItem("contact") || "",
    language: "English",
    timeZone: "Australia/Sydney",
    confirmNewPassword: "",
    password: "",
    isoCode: "au",
    dialCode: "61"
  };
  const getLabelTitle = () => {
    if (!authStore?.user?.isEmailVerified) return "Email";
    else if (!authStore?.user?.isMobileVerified) return "Mobile";
    else return "Mobile";
  };

  const handleFormSubmit = ({ contact, ...data }, setSubmitting) => {
    const formatedVal = getParsedContact(contact);
    const info = {
      ...checkEmailOrPhone(formatedVal),
      dialCode: data.dialCode
    };
    localStorage.setItem("contact", formatedVal);
    localStorage.setItem(
      "additional-info",
      JSON.stringify({ ...info, ...data })
    );
    authStore
      .verifyUserEmail(info)
      .then(res => {
        if (res.status) {
          // handleModal();
          setIndexKey(2);
        }
      })
      .catch(er => console.log(er))
      .finally(() => setSubmitting(false));
  };

  const MessageSchema = Yup.object().shape({
    gender: Yup.string(),
    companyName: Yup.string()
      .min(1, "Too Short!")
      .max(50, "Using too many characters!"),
    // .required("Company Name is required!"),
    designation: Yup.string(),
    contact: Yup.string().when([], {
      is: () => !authStore?.user?.isEmailVerified,
      then: Yup.string()
        .email("Invalid Email Address")
        .required("Email is required"),
      otherwise: Yup.string()
        .matches(
          validAusNumberRegExpDeluxe,
          "Please enter a valid phone number"
        )
        .required("Invalid Phone Number")
    }),
    language: Yup.string(),
    timeZone: Yup.string(),
    password: Yup.string()
      .min(6, "Password must be 6 characters atleast")
      .max(20, "Password not more than 20 characters"),
    // .required("New Password is required"),
    confirmNewPassword: Yup.string().when(["password"], {
      is: val => {
        return val;
      },
      then: Yup.string()
        .required("Confirm New Password is required")
        .oneOf([Yup.ref("password"), null], "Passwords must match"),
      otherwise: Yup.string()
    }),
    dialCode: Yup.string()
  });

  const formikForm = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: MessageSchema,
    onSubmit: async (values, { setSubmitting }) => {
      handleFormSubmit(values, setSubmitting);
    }
  });

  return (
    <>
      <form
        onSubmit={event => {
          event.preventDefault();
          formikForm.handleSubmit();
        }}
      >
        <Tabs defaultActiveKey="1" activeKey={`${indexKey}`}>
          <TabPane tab="Contact" key="1">
            <FloatLabel
              label={getLabelTitle()}
              value={formikForm.values.contact}
            >
              <ContactInput
                label={null}
                containerProps={{ colon: false }}
                placeholder={null}
                value={formikForm.values.contact}
                error={formikForm.errors.contact}
                touched={formikForm.touched.contact}
                containerClassname="mx-1 mt-3"
                name="contact"
                type="text"
                onChange={(contact, dialCode) => {
                  formikForm.setFieldValue("contact", contact);
                  formikForm.setFieldValue("dialCode", dialCode);
                }}
              />
            </FloatLabel>
          </TabPane>
          <TabPane tab="Verification" key="2">
            <EmailVerifyForm />
          </TabPane>
        </Tabs>
        <div className="text-end w-100">
          {indexKey == "1" && (
            <AppButton
              light
              label="verify"
              type="submit"
              className="mx-2"
              disabled={formikForm.isSubmitting}
            />
          )}
        </div>
      </form>
      <EmailOtpModal />
    </>
  );
};

export default inject(stores => ({
  authStore: stores.store.authStore,
  modalStore: stores.store.modalStore
}))(observer(View));
