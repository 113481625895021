import React from "react";
import { observer, inject } from "mobx-react";
import { Modal } from "antd";
import MergeLabels from "./MergeLabels";
import styles from "assets/css/modals.module.css";
import FinalImportForm from "../GroupForms/FileImportForm";

const ImportContact = props => {
  const { modalStore } = props;

  return (
    <>
      <Modal
        title={
          <div className="text-center">
            <h4 className="pt-3 fw-bold">Import Contacts</h4>
          </div>
        }
        closeIcon={
          <div className={`btn ${styles.modalCloseBtn}`}>
            <i className="fas fa-times"></i>
          </div>
        }
        visible={modalStore.showImportContactsModal}
        onCancel={() =>
          modalStore.toggleModal("showImportContactsModal", false)
        }
        maxWidth="850px"
        footer={null}
        centered
        maskStyle={{
          background: "linear-gradient(135deg, #00000050, #107c9850)"
        }}
      >
        <div className="px-5">
          <FinalImportForm />
        </div>
      </Modal>
      <MergeLabels />
    </>
  );
};

export default inject(stores => ({
  modalStore: stores.store.modalStore
}))(observer(ImportContact));
