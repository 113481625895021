import React, { useState, useEffect } from "react";
import OtpInput from "react18-input-otp";
import styles from "components/Authentication/Common/auth.module.css";
import { useFormikContext } from "formik";

function OtpInputComponent({ value, onChange, isDisabled, inputStyle = "", containerStyle = "" }) {
  return (
    <React.Fragment>
      <OtpInput
        value={value}
        shouldAutoFocus={true}
        onChange={otp => onChange(otp)}
        numInputs={6}
        isDisabled={isDisabled}
        separator={<span>&nbsp;</span>}
        containerStyle={`justify-content-evenly ${containerStyle}`}
        isInputNum
        inputStyle={`border-dark rounded rounded-3 border border-secondary ${styles.otpInput} ${inputStyle}`}
      />
    </React.Fragment>
  );
}

const AutoSubmit = () => {
  const { values, submitForm } = useFormikContext();
  useEffect(() => {
    if (values.otp?.length === 6) {
      setTimeout(() => submitForm(), 100);
    }
  }, [values]);

  return null;
};

const EmailMfaAutoSubmit = () => {
  const { values, submitForm } = useFormikContext();
  useEffect(() => {
    if (values?.mfaEmail) {
      console.log(values);
      setTimeout(() => submitForm(), 100);
    }
  }, [values]);

  return null;
};

export { OtpInputComponent, AutoSubmit, EmailMfaAutoSubmit };
