import React from "react";
import { inject, observer } from "mobx-react";
import AppButton from "components/AppButton/AppButton";
import ShortURL from "../Modals/ShortUrl";

function AddShortUrl(props) {
  const { modalStore, onSubmit, btnProps = {}, size = "large" } = props;

  function handleClick() {
    toggleModal(true);
  }

  function toggleModal(show = true) {
    modalStore.toggleModal("showShortUrlModal", show);
  }

  function handleSubmit(values) {
    modalStore.setData("longUrl", values.longUrl);
    toggleModal(false);
    onSubmit(values.longUrl);
  }

  return (
    <>
      <AppButton
        className="border-0 bg-light"
        prefixIcon={
          <span className="text-decoration-underline text-info-color">
            Add Short URL
          </span>
        }
        onClick={handleClick}
        size={size}
        {...btnProps}
      />
      <ShortURL
        isVisible={modalStore.showShortUrlModal}
        handleCancel={() => toggleModal(false)}
        onSubmit={handleSubmit}
      />
    </>
  );
}

export default inject(stores => ({
  modalStore: stores.store.modalStore
}))(observer(AddShortUrl));
