import React, { useState, useMemo, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { inject, observer } from "mobx-react";
import { Menu, Icon, Dropdown, message, Divider } from "antd";
import AppButton from "components/AppButton/AppButton";
import ImportContact from "components/TargetGroup/Modals/ImportContact";
import { sharedNumber } from "constant/constant";
import CreateCampaignModal from "components/Modals/CreateCampaignModal";

const iconStyles = {
  verticalAlign: "baseline",
  fontSize: "1rem"
};

const CustomDropdown = props => {
  const { authStore, modalStore, messageStore, balance, gatewayMmsStore, gatewayBulkStore } = props;
  const { user, allSenders } = authStore;
  const [active, setActive] = useState(false);

  const location = useLocation();
  const history = useHistory();
  const { setSearch, setPage, setPageSize } = useMemo(() => {
    if (modalStore.isMms) {
      return gatewayMmsStore;
    } else {
      return gatewayBulkStore;
    }
  }, [modalStore.isMms]);

  useEffect(() => {
    if (
      location.pathname === "/campaign/create" ||
      location.pathname === "/inbox-messaging" ||
      location.pathname === "/quick-message" ||
      location.pathname === "/conversation" ||
      location.pathname === "/template/add"
    ) {
      modalStore.toggleModal("quickMessage", false);
    }
  }, [location.pathname]);

  let dropdownMenu = [
    {
      key: "quick",
      icon: "message",
      label: "Quick Message"
    },
    ((user.role == "subaccount" && user.access.includes("sms" || "mms")) || user.role !== "subaccount") && {
      key: "campaign",
      icon: "inbox",
      label: "Campaign"
    },
    ((user.role == "subaccount" && user.access.includes("group")) || user.role !== "subaccount") && {
      key: "group",
      icon: "usergroup-add",
      label: "Group"
    },
    ((user.role == "subaccount" && user.access.includes("template")) || user.role !== "subaccount") && {
      key: "template",
      icon: "file-text",
      label: "Template"
    }
  ].filter(Boolean);

  const goToCampaign = () => {
    modalStore.toggleModal("createCampaignModal", true);
    // setSearch("");
    // setPage(1);
    // setPageSize(10);
    // history.push("/campaign");
  };

  const showQuickSmsModal = () => {
    setActive(!active);
    modalStore.setData("quickSMSContent", "");
    messageStore.setCurrentMessage("");
    messageStore.setPhoneSubject("");
    if (modalStore.isMms) {
      messageStore.setPhoneSender(user?.mobileSenders.includes(user?.defaultSender) ? user?.defaultSender : sharedNumber);
    } else {
      messageStore.setPhoneSender(allSenders.find(item => item.value === user?.defaultSender) ? user?.defaultSender : sharedNumber);
    }
    messageStore.phoneFrameImageUrl = undefined;
    messageStore.phoneFrameImageFileUrl = undefined;
    if (
      location.pathname !== "/campaign/create" ||
      location.pathname !== "/inbox-messaging" ||
      location.pathname !== "/conversation" ||
      location.pathname !== "/quick-message" ||
      location.pathname !== "/template/add"
    ) {
      localStorage.setItem("lastLocation", location.pathname);
      history.push("/quick-message");
    } else {
      history.push("/quick-message");
      // modalStore.toggleModal("quickMessage", !modalStore.quickMessage);
    }
    // modalStore.toggleModal("showQuickSMSModal", true);
  };

  const goToGroup = () => {
    history.push("/group");
  };

  const goToTemplate = () => {
    history.push("/template");
  };

  const handleActions = e => {
    switch (e.key) {
      case "quick":
        showQuickSmsModal();
        break;
      case "campaign":
        goToCampaign();
        break;
      case "group":
        goToGroup();
        break;
      case "template":
        goToTemplate();
        break;
      case "automation":
        history.push("/automation/contact-form");
        break;
      case "fileScheduler":
        history.push("/fileScheduler");
        break;
      case "emailSms":
        history.push("/email-sms");
        break;
      default:
        message.error("Something went wrong");
        break;
    }
  };

  const menu = (
    <Menu style={{ width: 165 }} className="rounded" onClick={handleActions}>
      {dropdownMenu.map(item => (
        <Menu.Item className="m-2 bg-light-color rounded" key={item.key}>
          <Icon type={item.icon} style={iconStyles} />
          <span className="fw-bold">{item.label}</span>
        </Menu.Item>
      ))}
      <Divider className="my-2" />
      <Menu.Item className="m-2 bg-light-color rounded" key="balance" disabled>
        <span className="bg-light-color rounded text-dark">SMS: {balance?.sms}</span>
        <br />
        <span className="bg-light-color rounded text-dark">MMS: {balance?.mms}</span>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <Dropdown overlay={menu} trigger={["click"]}>
        <AppButton light label="Create" className="rounded-1" />
      </Dropdown>
      <ImportContact />
      <CreateCampaignModal />
    </>
  );
};

export default inject(stores => ({
  authStore: stores.store.authStore,
  modalStore: stores.store.modalStore,
  messageStore: stores.store.messageStore,
  gatewayBulkStore: stores.store.gatewayBulkStore,
  gatewayMmsStore: stores.store.gatewayMmsStore
}))(observer(CustomDropdown));
