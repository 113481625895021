import { runInAction, decorate, observable } from "mobx";
import { shorturldetail } from "../../service/shortUrlService";

class ObservableShortUrlStore {
  shorturlResponse = undefined;

  sendShortUrlData = async (shorturlcode, shorturldata) => {
    const sendShortResponse = await shorturldetail(shorturlcode, shorturldata);
    try {
      runInAction(() => {
        this.shorturlResponse = sendShortResponse;
      });
    } catch (error) {
      runInAction(() => {
        this.shorturlResponse = error;
      });
    }
    return sendShortResponse;
  };
}

decorate(ObservableShortUrlStore, {
  shorturlResponse: observable
});

export default new ObservableShortUrlStore();
