import { useFormikContext } from "formik";
import React, { useEffect } from "react";
import { inject, observer } from "mobx-react";
import { isEmpty } from "lodash";

const SaveQuickMessageForm = ({ modalStore, messageStore }) => {
  const { values } = useFormikContext();
  useEffect(() => {
    // if (!isEmpty(values.sender)) modalStore.setData("quickSMSSender", values.sender);
    // if (!isEmpty(values.contacts)) modalStore.setData("quickSMSContact", values.contacts);
    if (!isEmpty(values.subject)) modalStore.setData("subject", values.subject);
    if (!isEmpty(values.message))
      modalStore.setData("quickSMSContent", values.message);
    if (!isEmpty(values.longUrl)) modalStore.setData("longUrl", values.longUrl);
    if (values?.media) messageStore.setCurrentImage(values?.media);
  }, [values]);

  return null;
};

export default inject(stores => ({
  modalStore: stores.store.modalStore,
  messageStore: stores.store.messageStore
}))(observer(SaveQuickMessageForm));
