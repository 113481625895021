import React, { useState, useEffect } from "react";
import { inject, observer } from "mobx-react";
import { Modal, Typography } from "antd";
import { checkIsEnglish, compileMessageForLength, caculateMessageLengthWithLineBreaks, checkTotalMessageCount } from "common/utils/messageUtilities";
import { langRegEx, optOutMessageSample, replyStopMessageSample, replyLinkMessageSample } from "constant/messageConstants";
import AppButton from "components/AppButton/AppButton";
import UnicodeDetector from "components/GatewayMessage/Unicode/UnicodeDetector";
import SMSCounter from "./SmsCounter";
import styles from "assets/css/modals.module.css";

const CharactersCounter = props => {
  const { name, isMms, balance, values, contacts, message, modalStore, messageStore, setFieldValue, setMessage, smallSize = false } = props;
  const [totalCharacterCount, setTotalCharacterCountstate] = useState(0);
  const [totalMessagesCount, setTotalMessageCountState] = useState(0);
  const [msgLimit, setMsgLimit] = useState(false);

  // optout & sms count effect
  useEffect(() => {
    if (modalStore.showCreateTemplateModal) return;
    const { optout, replyLink, replyStopToOptOut } = values;
    if (optout || replyStopToOptOut || replyLink) {
      messageStore.setCurrentMessage(
        <Typography.Text>
          {message}
          <br />
          {replyStopToOptOut && replyStopMessageSample}
          {optout && optOutMessageSample}
          {replyLink && replyLinkMessageSample}
        </Typography.Text>
      );
    } else {
      messageStore.setCurrentMessage(<Typography.Text>{message}</Typography.Text>);
    }
    let tempMessage = compileMessageForLength({
      message,
      optOut: optout,
      replyStopToOptOut,
      replyLink
    });
    setTotalCharacterCountstate(caculateMessageLengthWithLineBreaks(tempMessage));

    setTotalMessageCountState(checkTotalMessageCount(totalCharacterCount, checkIsEnglish(message)));
    if (message && (isMms ? totalCharacterCount > (langRegEx.test(message) ? 1500 : 600) : totalCharacterCount > (langRegEx.test(message) ? 1224 : 536))) {
      setMsgLimit(true);
    }
  }, [message, totalCharacterCount, values]);

  return (
    <>
      <SMSCounter
        characters={totalCharacterCount || 0}
        sms={totalMessagesCount || 1}
        contacts={contacts}
        credits={balance}
        approx={values.replyStopToOptOut || values.optout || values.replyLink}
        isMms={isMms}
        mobile={smallSize}
      />
      <UnicodeDetector
        message={message}
        onChange={e => {
          setFieldValue(name, e);
          setMessage(e);
        }}
        textBoxComp={<textarea className={styles.removeUnicodeTextarea} value={message} maxLength={isMms ? (langRegEx.test(message) ? 1500 : 600) : langRegEx.test(message) ? 1224 : 536} />}
      />
      <Modal visible={msgLimit} closable={false} footer={null} zIndex={1032}>
        <div className="text-center">
          <h6 className="fw-bold w-75 mx-auto">
            {isMms
              ? langRegEx.test(message)
                ? "You have exceeded the maximum character limit of 1500"
                : "You have exceeded the maximum character limit of 600"
              : langRegEx.test(message)
              ? "Maximum 8 SMS per contact allowed (1224 characters)"
              : "Maximum 8 SMS per contact allowed (536 unicode characters)"}
          </h6>
          <span>Edit your message here</span>
          <SMSCounter characters={totalCharacterCount} isMms={true} limit={isMms ? (langRegEx.test(message) ? 1500 : 600) : langRegEx.test(message) ? 1224 : 536} />
          <textarea
            className={`border border-info-color px-3 rounded ${styles.characterTextarea}`}
            value={message}
            name={name}
            onChange={e => {
              setFieldValue(name, e.target.value);
              setMessage(e.target.value);
            }}
          />
          <AppButton
            label="OK"
            light
            className="px-3"
            onClick={() => setMsgLimit(false)}
            disabled={isMms ? totalCharacterCount > (langRegEx.test(message) ? 1500 : 600) : totalCharacterCount > (langRegEx.test(message) ? 1224 : 536)}
          />
        </div>
      </Modal>
    </>
  );
};

export default inject(stores => ({
  messageStore: stores.store.messageStore,
  modalStore: stores.store.modalStore
}))(observer(CharactersCounter));
