// handle Mouse flow Script
export const addMouseflowScript = isValidURL => {
  const head = document.getElementsByTagName("head")[0];
  window._mfq = window._mfq || [];
  var mf = document.createElement("script");
  mf.id = "mouseflow-tracking-script";
  mf.type = "text/javascript";
  mf.defer = true;
  mf.src = "//cdn.mouseflow.com/projects/450dad3d-d7d2-42b8-8733-e95c8ff236f6.js";
  if (isValidURL) {
    head.appendChild(mf);
  } else {
    removeMouseflowScript();
  }
};

export const removeMouseflowScript = () => {
  const script = document.getElementById("mouseflow-tracking-script");
  if (script) document.head.removeChild(script);
};

// handle hubspot script
export const addHubspotScript = () => {
  if (window.location.host !== "app.gunisms.com.au") return;
  if (document.getElementById("hs-script-loader")) return;

  const head = document.getElementsByTagName("head")[0];
  let hs1 = document.createElement("script");
  hs1.id = "hs-script-loader";
  hs1.type = "text/javascript";
  hs1.async = true;
  hs1.defer = true;
  hs1.src = "//js-na1.hs-scripts.com/24047932.js";

  let hs2 = document.createElement("script");
  hs2.id = "hs-script-loader";
  hs2.type = "text/javascript";
  hs2.async = true;
  hs2.defer = true;
  hs2.src = "//js.hs-scripts.com/24047932.js";

  head.appendChild(hs1);
  head.appendChild(hs2);
};

export const removeHubspotScript = () => {
  const script = document.getElementById("hs-script-loader");
  if (script) document.head.removeChild(script);
};

// handle live chat script
export const addLiveChatScript = () => {
  const script = `window.__lc = window.__lc || {};
    window.__lc.license = 12632517;
    (function (n, t, c) {
      function i(n) {
        return e._h ? e._h.apply(null, n) : e._q.push(n);
      }
      var e = {
        _q: [],
        _h: null,
        _v: "2.0",
        on: function () {
          i(["on", c.call(arguments)]);
        },
        once: function () {
          i(["once", c.call(arguments)]);
        },
        off: function () {
          i(["off", c.call(arguments)]);
        },
        get: function () {
          if (!e._h)
            throw new Error(
              "[LiveChatWidget] You can't use getters before load."
            );
          return i(["get", c.call(arguments)]);
        },
        call: function () {
          i(["call", c.call(arguments)]);
        },
        init: function () {
          var n = t.createElement("script");
          (n.async = !0),
            (n.type = "text/javascript"),
            (n.src = "https://cdn.livechatinc.com/tracking.js"),
            t.head.appendChild(n);
        },
      };
      !n.__lc.asyncInit && e.init(),
        (n.LiveChatWidget = n.LiveChatWidget || e);
    })(window, document, [].slice);
    `;

  if (document.getElementById("live-chat-script")) return;
  const head = document.getElementsByTagName("head")[0];
  const lc = document.createElement("script");
  lc.id = "live-chat-script";
  lc.type = "text/javascript";
  lc.appendChild(document.createTextNode(script));
  head.appendChild(lc);
};

export const removeLiveChatScript = () => {
  const script = document.getElementById("live-chat-script");
  if (script) {
    document.head.removeChild(script);
    delete window.__lc;
    delete window.LiveChatWidget;
  }
};

// handle Google Tag Manager
export const addGoogleTagManagerScript = () => {
  if (document.getElementById("gtm-script")) return;

  const head = document.getElementsByTagName("head")[0];

  // Check for IE or Edge and redirect if necessary
  if (window.document.documentMode) {
    window.location.href = "./UnsupportedBrowser.html";
  }

  const gtmScript = document.createElement("script");
  gtmScript.id = "gtm-script";
  gtmScript.type = "text/javascript";
  gtmScript.async = true;
  gtmScript.defer = true;
  gtmScript.text = `
    (function(w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push({'gtm.start': new Date().getTime(), event: 'gtm.js'});
      var f = d.getElementsByTagName(s)[0], j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';
      j.async = true;
      j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
      f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', 'GTM-KWZJV7S');
  `;
  head.appendChild(gtmScript);

  const gtmIframe = document.createElement("noscript");
  gtmIframe.innerHTML = `
    <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-KWZJV7S" height="0" width="0" style="display:none;visibility:hidden" loading="lazy"></iframe>
  `;
  document.body.appendChild(gtmIframe);
};

export const removeGoogleTagManagerScript = () => {
  const script = document.getElementById("gtm-script");
  if (script) document.head.removeChild(script);

  const iframe = document.querySelector('iframe[src*="googletagmanager.com/ns.html"]');
  if (iframe) document.body.removeChild(iframe);
};

// handle LinkedIn
export const addLinkedInScript = () => {
  if (document.getElementById("linkedin-script")) return;

  const linkedinScript = document.createElement("script");
  linkedinScript.id = "linkedin-script";
  linkedinScript.type = "text/javascript";
  linkedinScript.text = `
    _linkedin_partner_id = "6745108";
    window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
    window._linkedin_data_partner_ids.push(_linkedin_partner_id);
    (function(l) {
      if (!l) {
        window.lintrk = function(a, b) { window.lintrk.q.push([a, b]) };
        window.lintrk.q = [];
      }
      var s = document.getElementsByTagName("script")[0];
      var b = document.createElement("script");
      b.type = "text/javascript";
      b.async = true;
      b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
      s.parentNode.insertBefore(b, s);
    })(window.lintrk);
  `;
  document.head.appendChild(linkedinScript);

  // Create LinkedIn noscript fallback
  const linkedinNoscript = document.createElement("noscript");
  linkedinNoscript.innerHTML = `
    <img height="1" width="1" style="display:none;" alt=""
      src="https://px.ads.linkedin.com/collect/?pid=6745108&fmt=gif" />
  `;
  document.body.appendChild(linkedinNoscript);
};

export const removeLinkedInScript = () => {
  const script = document.getElementById("linkedin-script");
  if (script) document.head.removeChild(script);

  const img = document.querySelector('img[src*="px.ads.linkedin.com/collect"]');
  if (img) document.body.removeChild(img);
};
