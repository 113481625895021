import React, { useRef, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { inject, observer } from "mobx-react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Form } from "antd";
import FormInput from "components/FormItems/FormInput";
import AppButton from "components/AppButton/AppButton";
import AlertModal from "components/Modals/AlertModal";
import { validAusNumberRegExpDeluxe } from "constant/messageConstants";

const validationSchema = Yup.object().shape({
  number: Yup.string()
    .matches(validAusNumberRegExpDeluxe, "Invalid phone number")
    .required("Mobile No. is required")
});

const ContactForm = props => {
  const { groupStore, authStore, modalStore, contact, setContact } = props;
  const {
    group,
    getAllGroupDataList,
    addSingleContact,
    updateSingleContact,
    getGroupById
  } = groupStore;
  const { id } = useParams();
  const history = useHistory();
  const formikRef = useRef();
  const [successAlert, setSuccessAlert] = useState(false);
  const [contactLimitAlert, setContactLimitAlert] = useState(false);

  let updateValues = {};

  if (contact) {
    contact.fields.map(field => {
      updateValues[field.name] = field.value;
      return updateValues;
    });
  }

  let initialValues = group?.fields.reduce((acc, curr) => {
    acc[curr] = "";
    return acc;
  }, {});

  if (contact) {
    initialValues = {
      number: `${contact.number}`,
      ...updateValues
    };
  } else {
    initialValues = group?.fields.reduce(
      (acc, curr) => {
        acc[curr] = "";
        return acc;
      },
      { number: "" }
    );
  }
  return (
    <>
      <Formik
        innerRef={formikRef}
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          if (contact) {
            updateSingleContact({ data: values }, id, contact._id)
              .then(res => {
                if (res.status) {
                  setSuccessAlert(true);
                } else {
                  setContact(null);
                }
              })
              .finally(() => {
                modalStore.toggleModal("showQuickContactModal", false);
                setSubmitting(false);
                resetForm();
              });
          } else {
            if (
              parseInt(group.totalContacts) === authStore.user?.contactLimit
            ) {
              setContactLimitAlert(true);
              modalStore.toggleModal("showQuickContactModal", false);
              setSubmitting(false);
              resetForm();
              return false;
            }
            addSingleContact({ data: values }, group._id)
              .then(res => {
                if (res.status) {
                  getAllGroupDataList(group._id);
                  getGroupById(group._id);
                  setSuccessAlert(true);
                }
              })
              .finally(() => {
                modalStore.toggleModal("showQuickContactModal", false);
                setSubmitting(false);
                resetForm();
              });
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          isSubmitting
        }) => {
          return (
            <Form id="test-sms" onSubmit={handleSubmit}>
              <FormInput
                label={
                  <span className="fw-bold text-capitalize">Mobile No.</span>
                }
                name="number"
                placeholder={`Enter Number`}
                value={values?.number}
                onChange={handleChange}
                containerProps={{
                  colon: false
                }}
                error={errors.number}
                touched={touched.number}
              />
              {group?.fields.map((field, index) => {
                return (
                  <FormInput
                    label={
                      <span className="fw-bold text-capitalize">{field}</span>
                    }
                    name={field}
                    placeholder={`Enter ${field}`}
                    value={values[field]}
                    onChange={handleChange}
                    containerProps={{
                      colon: false
                    }}
                    key={index}
                  />
                );
              })}
              <AppButton
                type="submit"
                label={contact ? "Update Contact" : "Add Contact"}
                light
                center
                disabled={isSubmitting}
              />
            </Form>
          );
        }}
      </Formik>
      <AlertModal
        visible={successAlert}
        title={`Contact ${contact ? "updated" : "added"} successfully`}
        primaryBtnProps={{
          label: "Ok",
          onClick: () => {
            setSuccessAlert(false);
            history.push(`/group/${group._id}`);
            if (contact) setContact(null);
          }
        }}
        closable
        onCancel={() => setSuccessAlert(false)}
      />
      <AlertModal
        visible={contactLimitAlert}
        title={`Contact limit exceeded!`}
        description={`Your per group contact limit is ${authStore.user?.contactLimit}`}
        error
        primaryBtnProps={{
          label: "Ok",
          onClick: () => setContactLimitAlert(false)
        }}
        closable
        onCancel={() => setContactLimitAlert(false)}
      />
    </>
  );
};

export default inject(stores => ({
  groupStore: stores.store.groupStore,
  authStore: stores.store.authStore,
  modalStore: stores.store.modalStore
}))(observer(ContactForm));
