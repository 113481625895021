import { decorate, observable, runInAction } from "mobx";
import {
  addParam,
  deleteParam,
  getAllParam,
  updateParam
} from "service/paramService";

class ObservableParamStore {
  paramList = [];
  addParamResponse = undefined;
  updateParamResponse = undefined;
  selectedParam = undefined;
  deleteParamResponse = undefined;

  /**
   * get all param
   * @returns {Promise<[]>}
   */
  getAllParamList = async () => {
    const paramListResponse = await getAllParam();
    try {
      runInAction(() => {
        this.paramList = paramListResponse;
        this.selectedParam = undefined;
      });
    } catch (error) {
      runInAction(() => {
        this.status = "error";
      });
    }
    return this.paramList;
  };

  /**
   * add new param
   * @returns {Promise<*>}
   * @param paramData
   */
  addParam = async paramData => {
    const addParamResponse = await addParam(paramData);
    try {
      runInAction(() => {
        this.addParamResponse = addParamResponse;
        this.getAllParamList();
      });
    } catch (error) {
      runInAction(() => {
        this.addParamResponse = error;
      });
    }
    return this.addParamResponse;
  };

  /**
   * select Test Case Data for Update
   * @param paramData
   */
  selectParam = paramData => {
    this.selectedParam = paramData
      ? JSON.parse(JSON.stringify(paramData))
      : undefined;
  };

  /**
   * updates Param Data
   * @param paramData
   * @param paramId
   * @returns {Promise<void>}
   */
  updateParam = async (paramData, paramId) => {
    const updateParamResponse = await updateParam(paramData, paramId);
    try {
      runInAction(() => {
        this.updateParamResponse = updateParamResponse;
        this.selectedParam = undefined;
        this.getAllParamList();
      });
    } catch (error) {
      runInAction(() => {
        this.updateParamResponse = error;
      });
    }
    return this.updateParamResponse;
  };

  /**
   * Delete Test Case
   * @param paramId
   * @returns {Promise<void>}
   */
  deleteParam = async paramId => {
    const deleteParamResponse = await deleteParam(paramId);
    try {
      runInAction(() => {
        this.deleteParamResponse = deleteParamResponse;
        this.getAllParamList();
      });
    } catch (error) {
      runInAction(() => {
        this.deleteParamResponse = error;
      });
    }
    return this.updateParamResponse;
  };
}

decorate(ObservableParamStore, {
  paramList: observable,
  addParamResponse: observable,
  updateParamResponse: observable,
  selectedParam: observable,
  deleteParamResponse: observable
});
export default new ObservableParamStore();
