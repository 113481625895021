import { runInAction, decorate, observable } from "mobx";
import {
  addPersonalNumber,
  verifyPersonalNumber,
  getVerifyOTP,
  getPersonalNumbers,
  deletePersonalNumber,
  getPersonalNumbersAdmin,
  approveOrRejectPersonalNumber,
  deletePersonalNumberAdmin,
  addReference
} from "service/personalNumberService";

class ObservablePersonalNumberStore {
  personalNumberList = [];
  selectedNumber = undefined;
  selectedNum = undefined;
  reference = "";
  response = undefined;
  pageSize = 10;
  current = 1;
  search = "";
  totalPages = 1;
  total = 0;
  sortField = "createdAt";
  sortOrder = "descend";

  changeSorting = fieldObj => {
    runInAction(() => {
      const { field, sortOrder } = fieldObj;
      this.sortField = field;
      this.sortOrder = sortOrder;
    });
  };

  setSearch = search => {
    runInAction(() => {
      this.search = search;
      this.current = 1;
    });
  };

  setPage = current => {
    runInAction(() => {
      this.current = current;
    });
  };

  setPageSize = pageSize => {
    runInAction(() => {
      this.pageSize = pageSize;
      this.current = 1;
    });
  };

  selectNumberId = id => {
    runInAction(() => {
      this.selectedNumber = id;
    });
  };

  selectNumber = number => {
    runInAction(() => {
      this.selectedNum = number;
    });
  };

  setReference = async value => {
    runInAction(() => {
      this.reference = value;
    });
  };

  // admin  methods
  getPersonalNumbersAdmin = async () => {
    this.personalNumberList = [];
    const params = {
      page: this.current,
      sortField: this.sortField,
      sortOrder: this.sortOrder,
      pageSize: this.pageSize
    };
    if (this.search) params.search = this.search;
    const response = await getPersonalNumbersAdmin(params);
    try {
      runInAction(() => {
        const { items, total, current, limit: pageSize } = response;
        this.personalNumberList = items;
        this.pageSize = pageSize;
        this.current = current;
        this.total = total;
        this.response = "success";
      });
    } catch (error) {
      this.personalNumberList = [];
      this.response = error;
    }
    return this.response;
  };

  approveOrRejectPersonalNumber = async (id, val) => {
    const response = await approveOrRejectPersonalNumber(id, val);
    try {
      runInAction(() => {
        this.response = response;
      });
    } catch (error) {
      runInAction(() => {
        this.response = error;
      });
    }
    return this.response;
  };

  deletePersonalNumberAdmin = async id => {
    const response = await deletePersonalNumberAdmin(id);
    try {
      runInAction(() => {
        this.response = response;
        if (response.status) {
          this.personalNumberList = this.personalNumberList.filter(
            item => item._id !== id
          );
        }
      });
    } catch (error) {
      runInAction(() => {
        this.response = error;
      });
    }
    return this.response;
  };

  // user methods
  addPersonalNumber = async values => {
    const response = await addPersonalNumber(values);
    try {
      runInAction(() => {
        this.response = response;
        if (response.status) {
          this.personalNumberList = [
            {
              _id: response.data._id,
              status: "pending",
              number: values.number,
              createdAt: new Date(),
              reference: response.data.reference
                ? response.data.reference
                : undefined
            },
            ...this.personalNumberList
          ];
          this.selectNumber(response.data.number);
        }
      });
    } catch (error) {
      runInAction(() => {
        this.response = error;
      });
    }
    return this.response;
  };

  getPersonalNumbers = async () => {
    this.personalNumberList = [];
    const response = await getPersonalNumbers();
    try {
      runInAction(() => {
        this.personalNumberList = response.data;
        this.response = response.data;
      });
    } catch (error) {
      this.personalNumberList = [];
      this.response = error;
    }
    return this.response;
  };

  deletePersonalNumber = async id => {
    const response = await deletePersonalNumber(id);
    try {
      runInAction(() => {
        this.response = response;
        if (response.status) {
          this.personalNumberList = this.personalNumberList.filter(
            item => item._id !== id
          );
        }
      });
    } catch (error) {
      runInAction(() => {
        this.response = error;
      });
    }
    return this.response;
  };

  verifyPersonalNumber = async (id, otp) => {
    const response = await verifyPersonalNumber(id, otp);
    try {
      runInAction(() => {
        this.response = response;
        if (response.status) {
          this.personalNumberList = this.personalNumberList.map(item => {
            if (item._id === id) {
              item.status = "admin_pending";
            }
            return item;
          });
        }
      });
    } catch (error) {
      runInAction(() => {
        this.response = error;
      });
    }
    return this.response;
  };

  getVerifyOTP = async number => {
    const response = await getVerifyOTP(number);
    try {
      runInAction(() => {
        this.response = response;
      });
    } catch (error) {
      runInAction(() => {
        this.response = error;
      });
    }
    return this.response;
  };

  addReference = async (id, value) => {
    const response = await addReference(id, value);
    try {
      runInAction(() => {
        this.response = response;
        if (response.status) {
          this.personalNumberList = this.personalNumberList.map(item => {
            if (item._id === id) {
              item.reference = value.reference;
            }
            return item;
          });
        }
      });
    } catch (error) {
      runInAction(() => {
        this.response = error;
      });
    }
    return this.response;
  };
}

decorate(ObservablePersonalNumberStore, {
  personalNumberList: observable,
  selectedNumber: observable,
  selectedNum: observable,
  reference: observable,
  response: observable,
  pageSize: observable,
  current: observable,
  search: observable,
  totalPages: observable,
  total: observable,
  sortField: observable,
  sortOrder: observable
});
export default new ObservablePersonalNumberStore();
