import React, { useEffect } from "react";
import { Typography } from "antd";
import PhoneFrame from "components/PhoneFrame/PhoneFrame";
import {
  replyStopMessageSample,
  optOutMessageSample
} from "constant/messageConstants";

const PhoneContent = props => {
  const {
    selectedData,
    messageStore,
    isMms,
    template,
    name,
    smallFrame = false,
    visible
  } = props;
  useEffect(() => {
    if (visible) {
      if (selectedData && selectedData.sender) {
        messageStore.setPhoneSender(selectedData.sender);
      }
      if (selectedData && selectedData[name]) {
        messageStore.setCurrentMessage(
          <Typography.Text>
            {selectedData[name]}
            <br />
            {selectedData.replyStopToOptOut && replyStopMessageSample}
            {!selectedData.replyStopToOptOut &&
              selectedData.optout &&
              optOutMessageSample}
          </Typography.Text>
        );
      }
      if (isMms && selectedData && selectedData.subject) {
        messageStore.setPhoneSubject(selectedData.subject);
      }
      if (template) {
        if (isMms && selectedData && selectedData.media) {
          messageStore.setCurrentImage(selectedData.media, true);
        } else {
          messageStore.setCurrentImage(null);
        }
      } else {
        if (
          isMms &&
          selectedData &&
          selectedData.media &&
          selectedData.media.url
        ) {
          messageStore.setCurrentImage(selectedData.media.url, true);
        } else if (
          isMms &&
          selectedData &&
          selectedData.media &&
          selectedData.media.length > 0
        ) {
          messageStore.setCurrentImage(selectedData.media[0].url, true);
        } else {
          messageStore.setCurrentImage(null);
        }
      }
    } else {
      messageStore.setCurrentImage(null);
      messageStore.setPhoneSender("GUNI SMS");
      messageStore.setCurrentMessage("");
      messageStore.setPhoneSubject("");
    }
  }, [selectedData, visible]);
  return (
    <PhoneFrame
      headerName={messageStore.phoneFrameSender || "GUNI SMS"}
      currentMessageContent={messageStore.phoneFrameCurrentMessage}
      phoneFrameImageUrl={messageStore.phoneFrameImageUrl}
      isMms={isMms}
      smallFrame={smallFrame}
    />
  );
};

export default PhoneContent;
