import React from "react";
import loader from "assets/images/infinite-blue.svg";

const AppLoader = () => {
  return (
    <div className="w-100 text-center">
      <img src={loader} alt="loader" width={"100px"} />
    </div>
  );
};

export default AppLoader;
