import { runInAction, decorate, observable } from "mobx";
import {
  getAllSchedulers,
  addScheduler,
  deleteScheduler,
  updateScheduler,
  updateSchedulerStatus,
  getAllSchedulerLogList,
  getAllSchedulerHistoryList
} from "service/schedulerService";
import _ from "lodash";
import * as mobx from "mobx";

class ObservableSchedulerStore {
  updateSchedulerStatusResponse;
  schedulerList = [];
  schedulerLogList = [];
  schedulerHistoryList = [];
  addSchedulerResponse = undefined;
  updateSchedulerResponse = undefined;
  selectedScheduler = undefined;
  deleteSchedulerResponse = undefined;
  pageSize = 10;
  current = 1;
  totalPages = 0;
  sortField = "createdAt";
  sortOrder = "descend";
  search = "";

  changeSorting = fieldObj => {
    runInAction(() => {
      const { field, sortOrder } = fieldObj;
      this.sortField = field;
      this.sortOrder = sortOrder;
    });
  };

  setSearch = search => {
    runInAction(() => {
      this.search = search;
      this.getAllSchedulerList();
    });
  };

  setPage = current => {
    runInAction(() => {
      this.current = current || 1;
    });
  };

  /**
   * get all Schedulers
   * @returns {Promise<[]>}
   */
  getAllSchedulerList = async isAdd => {
    const params = {
      page: isAdd ? this.current + 1 : this.current,
      sortField: this.sortField,
      sortOrder: this.sortOrder
    };
    if (this.search) params.search = this.search;
    // call & save Scheduler list response
    const schedulerListResponse = await getAllSchedulers(params);
    try {
      runInAction(() => {
        const {
          items,
          total,
          current,
          limit: pageSize,
          totalPages
        } = schedulerListResponse;
        this.schedulerList = isAdd
          ? _.uniq(
              _.concat(
                mobx.toJS(this.schedulerList),
                items || schedulerListResponse
              ),
              "_id"
            )
          : _.uniq(items || schedulerListResponse, "_id");
        this.pageSize = pageSize;
        this.current = current;
        this.total = total;
        this.totalPages = totalPages;
        this.selectedScheduler = undefined;
      });
    } catch (error) {
      runInAction(() => {
        this.status = "error";
      });
    }

    return this.schedulerList;
  };

  getSchedulerHistory = async () => {
    const schedulerHistoryResponse = await getAllSchedulerHistoryList(
      this.selectedScheduler._id
    );
    try {
      runInAction(() => {
        this.schedulerHistoryList = schedulerHistoryResponse;
      });
    } catch (error) {
      this.schedulerHistoryList = error;
    }
    return this.schedulerHistoryList;
  };

  getScheduerLog = async () => {
    const schedulerLogListResponse = await getAllSchedulerLogList(
      this.selectedScheduler._id
    );
    try {
      runInAction(() => {
        this.schedulerLogList = schedulerLogListResponse;
      });
    } catch (error) {
      this.schedulerLogList = error;
    }
    return this.schedulerLogList;
  };

  /**
   * add new Scheduler
   * @param schedulerData
   * @returns {Promise<*>}
   */
  addScheduler = async schedulerData => {
    // call api for add new Scheduler
    const addSchedulerResponse = await addScheduler(schedulerData);
    try {
      runInAction(() => {
        this.addSchedulerResponse = addSchedulerResponse;
      });
      this.getAllSchedulerList();
    } catch (error) {
      runInAction(() => {
        this.addSchedulerResponse = error;
      });
    }
    return this.addSchedulerResponse;
  };

  /**
   * select Scheduler Data for Update
   * @param schedulerData
   */
  selectScheduler = schedulerData => {
    this.selectedScheduler = schedulerData
      ? JSON.parse(JSON.stringify(schedulerData))
      : undefined;
  };

  /**
   * updates Scheduler Data
   * @param schedulerData
   * @param schedulerId
   * @returns {Promise<void>}
   */
  updateScheduler = async (schedulerData, schedulerId) => {
    // call api for update existing Scheduler
    const updateSchedulerResponse = await updateScheduler(
      schedulerData,
      schedulerId
    );
    try {
      runInAction(() => {
        this.updateSchedulerResponse = updateSchedulerResponse;
        this.selectedScheduler = undefined;
      });
      this.getAllSchedulerList();
    } catch (error) {
      runInAction(() => {
        this.updateSchedulerResponse = error;
      });
    }
    return this.updateSchedulerResponse;
  };

  /**
   * Delete Scheduler
   * @param schedulerId
   * @returns {Promise<void>}
   */
  deleteScheduler = async schedulerId => {
    const deleteSchedulerResponse = await deleteScheduler(schedulerId);
    try {
      runInAction(() => {
        this.deleteSchedulerResponse = deleteSchedulerResponse;
      });
      this.getAllSchedulerList();
    } catch (error) {
      runInAction(() => {
        this.deleteSchedulerResponse = error;
      });
    }
    return this.updateSchedulerResponse;
  };

  updateSchedulerStatus = async schedulerId => {
    const updateSchedulerResponse = await updateSchedulerStatus(schedulerId);
    try {
      runInAction(() => {
        this.updateSchedulerResponse = updateSchedulerResponse;
      });
      this.getAllSchedulerList();
    } catch (error) {
      runInAction(() => {
        this.updateSchedulerResponse = error;
      });
    }
    return this.updateSchedulerResponse;
  };
}

decorate(ObservableSchedulerStore, {
  updateSchedulerStatusResponse: observable,
  schedulerList: observable,
  addSchedulerResponse: observable,
  updateSchedulerResponse: observable,
  selectedScheduler: observable,
  deleteSchedulerResponse: observable,
  pageSize: observable,
  current: observable,
  total: observable,
  sortField: observable,
  sortOrder: observable,
  search: observable
});
export default new ObservableSchedulerStore();
