import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { inject, observer } from "mobx-react";
import moment from "moment";
import { Col, Row, Typography, Tabs, notification, Tooltip, Popover } from "antd";
import AppTable from "components/AppTable/AppTable";
import AlertModal from "components/Modals/AlertModal";
import { compileMessageForLength, caculateMessageLengthWithLineBreaks } from "common/utils/messageUtilities";
import { dateTimeFormat, debounceTime, sharedNumber } from "constant/constant";
import debounce from "lodash.debounce";
import SearchMenu from "components/SearchMenu/SearchMenu";
import AppLoader from "components/AppLoader/Loader";
import AssignModal from "components/Modals/AssignModal";
import IconsMenu from "components/IconsMenu/IconsMenu";
import ContentDrawer from "components/ContentDrawer/ContentDrawer";
import CreateTemplate from "components/Template/Modals/TemplateModal";

const { TabPane } = Tabs;

function View(props) {
  const { preMade = true, authStore, templateStore, onTemplateSelect, active, smallSize, modalStore, userStore, gatewayBulkStore, gatewayMmsStore, messageStore, isMms } = props;

  const { user, allSenders } = authStore;
  const {
    search,
    setSearch,
    current,
    setPage,
    total,
    pageSize,
    setPageSize,
    visibility,
    setVisibility,
    templateList,
    getAllTemplateList,
    selectedTemplate,
    selectTemplate,
    deleteTemplate,
    getTemplateByUser,
    markAsGlobalTemplate,
    changeSorting,
    sortField,
    sortOrder
  } = templateStore;

  const history = useHistory();
  const location = useLocation();
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [assignModal, setAssignModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [debounceValue, setDebounceValue] = useState("");
  const [expandedRowKey, setExpandedRowKey] = useState([]);
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [tabMenu, setTabMenu] = useState(() => {
    return [{ label: "My Templates", value: "user" }, preMade && { label: "Pre-Made", value: "global" }].filter(Boolean);
  });
  const [contentVisible, setContentVisible] = useState(false);
  const [menuVisible, setMenuVisible] = useState(false);
  const [hoverData, setHoverData] = useState(null);
  const [refreshing, setRefreshing] = useState(false);

  const debouncedSave = useCallback(
    debounce(nextValue => setDebounceValue(nextValue), debounceTime),
    []
  );

  const { setCampaignData } = useMemo(() => {
    if (isMms) {
      return gatewayMmsStore;
    } else {
      return gatewayBulkStore;
    }
  }, [isMms]);

  const sync = useCallback(() => {
    setLoading(true);
    setRefreshing(true);
    const fetchData =
      id && location.pathname.includes("user-templates")
        ? getTemplateByUser(id)
        : getAllTemplateList({
            mode: isMms ? "mms" : "sms",
            visibility: isMms ? "user" : visibility
          });

    fetchData.finally(() => {
      setLoading(false);
      setRefreshing(false);
    });
  }, [id, location.pathname, isMms, visibility, getTemplateByUser, getAllTemplateList]);

  useEffect(() => {
    if (user?.role == "subaccount" && !user?.access?.includes("mms") && user?.role == "subaccount" && !user?.access?.includes("sms")) {
      history.push("/");
    } else {
      sync();
    }
  }, [debounceValue, current, pageSize, visibility, id, sortField, sortOrder, isMms, modalStore.showCreateTemplateModal, user, history, sync]);

  let templateColumns = [
    {
      title: "Name",
      dataIndex: "name",
      ellipsis: true,
      sorter: true,
      sortDirections: ["ascend", "descend"],
      onCell: record => ({ onClick: () => (active ? onTemplateSelect(record) : handleClick("edit", record)) }),
      render: (text, record) => {
        if (active) {
          return (
            <Tooltip
              title={
                <Typography.Paragraph className="text-break mb-0" style={{ whiteSpace: "break-spaces" }}>
                  {record.content}
                </Typography.Paragraph>
              }
              arrowPointAtCenter
              autoAdjustOverflow
              trigger="hover"
              placement="right"
            >
              {text}
            </Tooltip>
          );
        } else {
          return (
            <span role="button" className="fw-bold">
              {record?.assignedSubaccount && user?.role == "subaccount" && (
                <Tooltip title="Shared Template">
                  <i className="fas fa-star me-2 text-info-color" />
                </Tooltip>
              )}
              {record?.assignedSubaccount && user?.role !== "subaccount" && (
                <Popover
                  title="Assigned Subaccounts"
                  content={record?.subAccount.map((item, index) => (
                    <Typography.Paragraph copyable key={index}>
                      {item.email}
                    </Typography.Paragraph>
                  ))}
                >
                  <i className="fas fa-users me-2 text-info-color" />
                </Popover>
              )}
              <Popover
                title="Content"
                arrowPointAtCenter
                content={
                  <div className="text-wrap overflow-auto" style={{ maxWidth: "300px", maxHeight: "100px" }}>
                    {record?.content}
                  </div>
                }
              >
                {text}
              </Popover>
            </span>
          );
        }
      }
    }
  ];

  if (!active) {
    templateColumns.push(
      {
        title: "Added Date",
        dataIndex: "createdAt",
        ellipsis: true,
        render: (text, record) => {
          return moment(record.createdAt).format(dateTimeFormat);
        },
        sorter: true,
        sortDirections: ["ascend", "descend"]
      },
      {
        dataIndex: "actions",
        className: "text-center",
        render: (text, record) => {
          let menu = [
            user?.role === "subaccount"
              ? record.assignedSubaccount && record.subAccount.find(item => item === user?._id)
                ? false
                : {
                    label: record.global && user.role !== "superAdmin" ? "Use" : "Edit",
                    value: record.global && user.role !== "superAdmin" ? "copy" : "edit",
                    icon: record.global && user.role !== "superAdmin" ? "copy" : "edit"
                  }
              : {
                  label: record.global && user.role !== "superAdmin" ? "Use" : "Edit",
                  value: record.global && user.role !== "superAdmin" ? "copy" : "edit",
                  icon: record.global && user.role !== "superAdmin" ? "copy" : "edit"
                },
            { label: "Run Campaign", value: "campaign", icon: "send" },
            user?.role !== "subaccount" && {
              label: "Assign",
              value: "assign",
              icon: "users-cog"
            }
          ].filter(Boolean);
          if (!record.global || user.role === "superAdmin") {
            menu.push({ label: "Delete", value: "delete", icon: "trash-alt" });
          }
          if (user.role === "superAdmin" && !isMms) {
            menu.push({
              label: visibility == "global" ? "Unmark Pre-Made" : "Make Pre-Made",
              value: "global",
              icon: visibility == "global" ? "eye-slash" : "eye"
            });
          }
          if (menuVisible && hoverData._id == record._id)
            return (
              <IconsMenu
                options={menu}
                onClick={key => {
                  console.log(key, key?.stopPropagation);
                  handleClick(key, record);
                }}
              />
            );
          else return null;
        }
      }
    );
  }

  if (!smallSize) {
    templateColumns.splice(1, 0, {
      title: "Characters Count",
      dataIndex: "charactersCount",
      className: "text-center",
      ellipsis: true,
      render: (text, record) => {
        let tempMessage = compileMessageForLength({
          message: record.content
        });
        return caculateMessageLengthWithLineBreaks(tempMessage);
      }
    });

    if (isMms) {
      templateColumns.splice(1, 0, {
        title: "Subject",
        dataIndex: "subject",
        className: "text-start",
        ellipsis: true
      });
    }
  }

  const handleClick = (key, record) => {
    switch (key) {
      case "edit":
        selectTemplate(record);
        modalStore.toggleModal("showCreateTemplateModal", false);
        history.push(`/template/edit/${record._id}`);
        break;
      case "copy":
        selectTemplate(record);
        history.push(`/template/save/${record._id}`);
        break;
      case "delete":
        setDeleteAlert(true);
        selectTemplate(record);
        break;
      case "assign":
        selectTemplate(record);
        setAssignModal(true);
        break;
      case "campaign":
        let datasms = {
          message: record.content,
          camp_type: isMms ? "mms" : "sms"
        };
        if (isMms) {
          datasms.subject = record.subject;
          datasms.url = record.media;
        }
        localStorage.setItem("campaign", JSON.stringify(datasms));
        setCampaignData(datasms);
        history.push("/campaign/create");
        localStorage.setItem("step", 0);
        messageStore.setCurrentMessage(record.content);
        messageStore.setPhoneSubject("");
        messageStore.setPhoneSender(allSenders.find(item => item.value === user?.defaultSender) ? user?.defaultSender : sharedNumber);
        if (isMms && record && record.media) {
          messageStore.setCurrentImage(record.media, true);
        } else {
          messageStore.setCurrentImage(null);
        }
        break;
      case "global":
        markAsGlobalTemplate(record._id);
        break;
      default:
        notification.error({
          message: "Error",
          description: "Something went wrong"
        });
        break;
    }
  };

  const onTableRowExpand = (expended, record) => {
    setExpandedRowKey(expended ? [record._id] : []);
  };

  if (!user) {
    return <AppLoader />;
  }

  return (
    <>
      <Row gutter={[20, 20]} className="p-2">
        {id && location.pathname.includes("user-templates") ? (
          <>
            <Col span={1}>
              <i className="fas fa-arrow-left fs-5 text-info-color" onClick={() => history.push("/admin/users")} type="button" />
            </Col>
            <Col span={23} className="mt-3 text-center">
              <Typography.Title level={4}>{userStore.selectedUser?.name} Templates</Typography.Title>
            </Col>
          </>
        ) : (
          <Col span={24}>
            <SearchMenu
              placeholder={`Search for Template Name`}
              value={search}
              onSearch={e => {
                setSearch(e.target.value);
                debouncedSave(e.target.value);
              }}
              btn
              icon={active}
              size="default"
              label="Add Template"
              onClick={() => {
                if (active && !smallSize) {
                  modalStore.toggleModal("showCreateTemplateModal", true);
                } else {
                  history.push(`/template/add`);
                  modalStore.toggleModal("showChooseTemplateModal", false);
                }
                selectTemplate("");
              }}
              secondaryBtn={user.role === "superAdmin" && !isMms && !active}
              secondaryBtnProps={{
                label: "Add Global Template",
                onClick: () => {
                  history.push(`/template/add?global=true`);
                  selectTemplate("");
                }
              }}
              popConfirm={smallSize && active}
              refreshBtn={true}
              onRefresh={sync}
              refreshing={refreshing}
            />
          </Col>
        )}
        <Col span={24}>
          <Tabs onChange={e => setVisibility(e)} defaultActiveKey={visibility}>
            {tabMenu.map(item => (
              <TabPane tab={item.label} key={item.value}>
                <AppTable
                  loading={loading}
                  pagination={{
                    defaultCurrent: 1,
                    current: current,
                    total: total,
                    pageSize: pageSize,
                    onChange: current => setPage(current),
                    showSizeChanger: true,
                    onShowSizeChange: (current, pageSize) => setPageSize(pageSize),
                    className: "float-start",
                    hideOnSinglePage: true,
                    showQuickJumper: true
                  }}
                  columns={templateColumns}
                  dataSource={templateList}
                  scroll={{
                    x: active ? 300 : 750,
                    y: active ? 300 : modalStore.quickMessage ? 755 : 620
                  }}
                  secondary
                  rowKey={record => record._id}
                  onRow={record => {
                    if (active) {
                      return {
                        onClick: () => onTemplateSelect(record)
                      };
                    } else {
                      return {
                        // onClick: () => {
                        //   setContentVisible(true);
                        //   selectTemplate(record);
                        // },
                        onMouseEnter: () => {
                          setMenuVisible(true);
                          setHoverData(record);
                        },
                        onMouseLeave: () => {
                          setMenuVisible(false);
                          setHoverData(null);
                        }
                      };
                    }
                  }}
                  expandRowByClick={active}
                  onExpand={onTableRowExpand}
                  expandedRowKeys={expandedRowKey}
                  expandedRowRender={record => {
                    return (
                      <>
                        {isMms && (
                          <>
                            <Typography.Paragraph
                              className="text-break"
                              ellipsis={{
                                rows: 3,
                                expandable: true,
                                symbol: "more"
                              }}
                              style={{ whiteSpace: "break-spaces" }}
                            >
                              <Typography.Text strong>Subject:</Typography.Text> {record.subject}
                            </Typography.Paragraph>
                            {record.media && (
                              <div
                                style={{
                                  maxWidth: "fit-content",
                                  background: "#149cbe30"
                                }}
                                className="rounded p-4 mb-3"
                              >
                                <i className="fas fa-file fs-1 text-info-color" />
                              </div>
                            )}
                          </>
                        )}
                        <Typography.Paragraph
                          className="text-break"
                          ellipsis={{
                            rows: 3,
                            expandable: true,
                            symbol: "more"
                          }}
                          style={{ whiteSpace: "break-spaces" }}
                        >
                          <Typography.Text strong>Message:</Typography.Text> {record.content}
                        </Typography.Paragraph>
                      </>
                    );
                  }}
                  onChange={(pagination, filters, sorter, extra) =>
                    changeSorting({
                      sortOrder: sorter.order,
                      sortField: sorter.field
                    })
                  }
                />
              </TabPane>
            ))}
          </Tabs>
        </Col>
      </Row>
      <CreateTemplate isMms={isMms} />
      {!assignModal && (
        <ContentDrawer
          visible={contentVisible}
          handleClose={() => {
            selectTemplate(null);
            setContentVisible(false);
          }}
          label="Template Content"
          showDetails={false}
          isMms={isMms}
          selectedData={selectedTemplate}
          name="content"
          template
        />
      )}
      {!active && (
        <>
          <AlertModal
            visible={deleteAlert}
            title={`Delete - ${selectedTemplate?.name} Template`}
            description="Are you sure you want to delete this template?"
            loading={deleteLoading}
            primaryBtnProps={{
              onClick: () => {
                setDeleteLoading(true);
                deleteTemplate(selectedTemplate._id)
                  .then(res => {
                    if (res.status) {
                      setDeleteSuccess(true);
                    }
                  })
                  .finally(() => {
                    setDeleteAlert(false);
                    setDeleteLoading(false);
                  });
              }
            }}
            secondaryBtn
            secondaryBtnProps={{
              onClick: () => setDeleteAlert(false)
            }}
            error
          />
          <AlertModal
            visible={deleteSuccess}
            title="Template Deleted!"
            primaryBtnProps={{
              label: "Ok",
              onClick: () => setDeleteSuccess(false)
            }}
            closable
            onCancel={() => setDeleteSuccess(false)}
          />
        </>
      )}
      <AssignModal visible={assignModal} onCancel={() => setAssignModal(false)} template />
    </>
  );
}

export default inject(stores => ({
  authStore: stores.store.authStore,
  templateStore: stores.store.templateStore,
  modalStore: stores.store.modalStore,
  userStore: stores.store.userStore,
  gatewayBulkStore: stores.store.gatewayBulkStore,
  gatewayMmsStore: stores.store.gatewayMmsStore,
  messageStore: stores.store.messageStore
}))(observer(View));
