import { get, post, put, deleteRequest } from "utils/api";

/**
 * @desc get workflow for the user
 * @param {*} params : pagination
 */
export const getAllWorkflows = async params => {
  const getWorkFlowResponse = await get("/workflow", params);
  return getWorkFlowResponse.data.data;
};

/**
 *
 * @param {*} id : it is workflow's unique id
 * @returns the workflow related to id
 */
export const getWorkflowById = async id => {
  const getWorkFlowById = await get(`/workflow/getActions/${id}`);
  return getWorkFlowById.data;
};

/**
 *
 * @param {*} data
 * @returns created workflow response
 */
export const createWorkflow = async data => {
  const createWorkFlowResponse = await post("/workflow", data);
  return createWorkFlowResponse.data;
};

/**
 *
 * @param {*} data  :  name or category to be changed
 * @param {*} id  :  workflow id
 * @returns response from the server
 */
export const updateWorkflow = async (id, data) => {
  const editWorkFlowNameResponse = await put(`/workflow/editName/${id}`, data);
  return editWorkFlowNameResponse.data;
};

/**
 *
 * @param {*} data : it has id of the workflow, that needs to be active
 * @returns response from the server
 */
export const setActive = async data => {
  const setWorkFlowActiveResponse = await put("/workflow/setActive", data);
  return setWorkFlowActiveResponse.data;
};

/**
 *
 * @param {*} data : action data
 * @param {*} id : it has id of the workflow
 * @returns response from the server
 */
export const addAction = async (id, data) => {
  const addActionResponse = await post(`/workflow/addActions/${id}`, data);
  return addActionResponse.data;
};

/**
 *
 * @param {*} data : action data
 * @param {*} id : action id
 * @returns response from the server
 */
export const updateAction = async (id, data) => {
  const editActionsResponse = await put(`/workflow/editActions/${id}`, data);
  return editActionsResponse.data;
};

/**
 *
 * @param {*} id : it has id of the action
 * @returns response from the server
 */
export const deleteAction = async id => {
  const deleteResponse = await deleteRequest(`/workflow/deleteActions/${id}`);
  return deleteResponse.data;
};

/**
 *
 * @param {*} id : it has id of the workflow
 * @returns response from the server
 */
export const deleteWorkflow = async id => {
  const deleteResponse = await deleteRequest(`/workflow/${id}`);
  return deleteResponse.data;
};
