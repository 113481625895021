import { decorate, observable, runInAction } from "mobx";
import { cookie } from "utils/cookie/index";
import {
  inviteUser,
  acceptInvite,
  getAllSubAccounts,
  modifyAccountPermissions,
  removeSubAccount,
  assignGroupToSubAccount,
  assignTemplateToSubAccount,
  assignSavedBulkToSubAccount,
  updateRolePermission,
  loginAsSubAccount,
  checkEmailExists,
  createSubaccountPurchase
} from "service/subAccountService";
import { AUTH_TOKEN, AUTH_USER, REFRESH_TOKEN, expireTime } from "constant/constant";
import AuthStore from "store/auth/AuthStore";

class ObservableSubAccountStore {
  inviteUserResponse = undefined;
  acceptInviteResponse = undefined;
  updateSubAccountResponse = undefined;
  removeSubAccountResponse = undefined;
  selectedUser = undefined;
  subAccountsList = [];
  total = 0;
  pageSize = 10;
  current = 1;
  search = undefined;
  sortField = "createdAt";
  sortOrder = "descend";
  assigningGroupResponse = undefined;
  assigningTemplateResponse = undefined;
  assigningBulkResponse = undefined;
  response = undefined;
  subAccountPurchaseData = undefined;

  changeSorting = fieldObj => {
    runInAction(() => {
      const { field, sortOrder } = fieldObj;
      this.sortField = field;
      this.sortOrder = sortOrder;
    });
  };

  setSearch = search => {
    runInAction(() => {
      this.search = search;
      this.current = 1;
    });
  };

  setPage = current => {
    runInAction(() => {
      this.current = current;
    });
  };

  setPageSize = size => {
    runInAction(() => {
      this.pageSize = size;
    });
  };

  selectUser = async userData => {
    runInAction(() => {
      this.selectedUser = userData ? JSON.parse(JSON.stringify(userData)) : undefined;
    });
  };

  getAllSubAccounts = async args => {
    const params = {
      page: this.current,
      sortField: this.sortField,
      sortOrder: this.sortOrder,
      limit: this.pageSize,
      ...(args && args)
    };
    if (this.search) params.search = this.search;
    if (this.status) params.status = this.status;
    const response = await getAllSubAccounts(params);
    try {
      runInAction(() => {
        const { items, total, current, limit: pageSize } = response;
        this.subAccountsList = items;
        this.pageSize = pageSize;
        this.current = current;
        this.total = total;
      });
    } catch (error) {
      runInAction(() => {
        this.subAccountsList = error;
      });
    }
    return this.subAccountsList;
  };

  inviteUser = async data => {
    const inviteUserResponse = await inviteUser(data);
    try {
      runInAction(() => {
        this.inviteUserResponse = inviteUserResponse;
        if (this.inviteUserResponse.status) {
          this.getAllSubAccounts();
        }
      });
    } catch (error) {
      this.inviteUserResponse = error;
    }
    return this.inviteUserResponse;
  };

  acceptInvite = async (token, data) => {
    const acceptInviteResponse = await acceptInvite(token, data);
    try {
      runInAction(() => {
        this.acceptInviteResponse = acceptInviteResponse;
      });
    } catch (error) {
      this.acceptInviteResponse = error;
    }
    return this.acceptInviteResponse;
  };

  updateSubAccount = async data => {
    const updateSubAccountResponse = await modifyAccountPermissions(data);
    try {
      runInAction(() => {
        this.updateSubAccountResponse = updateSubAccountResponse;
        this.subAccountsList = this.subAccountsList.map(subAccount => {
          if (subAccount.inviteReciever === this.selectedUser.inviteReciever) {
            return {
              ...subAccount,
              access: data.access,
              user: {
                ...subAccount.user,
                mobile: data.mobile,
                mobileSenders: data.mobileSenders,
                senders: data.senders
              }
            };
          }
          return subAccount;
        });
      });
    } catch (error) {
      this.updateSubAccountResponse = error;
    }
    return this.updateSubAccountResponse;
  };

  createPurchaseSubaccount = async data => {
    const createPurchaseSubaccountResponse = await createSubaccountPurchase(data);

    try {
      this.subAccountPurchaseData = createPurchaseSubaccountResponse;
    } catch (error) {
      this.subAccountPurchaseData = createPurchaseSubaccountResponse = error;
    }

    return this.subAccountPurchaseData;
  };

  removeSubAccount = async data => {
    const res = await removeSubAccount(data);
    try {
      runInAction(() => {
        this.removeSubAccountResponse = res;
        if (res.status) {
          this.subAccountsList = this.subAccountsList.filter(item => item.inviteReciever !== data.email);
        }
      });
    } catch (error) {
      this.removeSubAccountResponse = error;
    }
    return this.removeSubAccountResponse;
  };

  assignGroup = async data => {
    const assigningGroupResponse = await assignGroupToSubAccount(data);
    try {
      runInAction(() => {
        this.assigningGroupResponse = assigningGroupResponse;
      });
    } catch (error) {
      this.assigningGroupResponse = error;
    }
    return this.assigningGroupResponse;
  };

  assignTemplate = async data => {
    const assigningTemplateResponse = await assignTemplateToSubAccount(data);
    try {
      runInAction(() => {
        this.assigningTemplateResponse = assigningTemplateResponse;
      });
    } catch (error) {
      this.assigningTemplateResponse = error;
    }
    return this.assigningTemplateResponse;
  };

  assignBulk = async data => {
    const assigningBulkResponse = await assignSavedBulkToSubAccount(data);
    try {
      runInAction(() => {
        this.assigningBulkResponse = assigningBulkResponse;
      });
    } catch (error) {
      this.assigningBulkResponse = error;
    }
    return this.assigningBulkResponse;
  };

  updateRolePermission = async data => {
    const updateRolePermissionResponse = await updateRolePermission(data);
    try {
      runInAction(() => {
        this.response = updateRolePermissionResponse;
      });
    } catch (error) {
      this.response = error;
    }
    return this.response;
  };

  updateUserData = async data => {
    try {
      runInAction(() => {
        this.subAccountsList = this.subAccountsList.map(subAccount => {
          if (subAccount.inviteReciever === this.selectedUser.inviteReciever) {
            return {
              ...subAccount,
              user: {
                ...subAccount.user,
                roleAccess: [
                  ...subAccount.user.roleAccess.map(item => {
                    if (item.feature === data.feature) {
                      return {
                        ...item,
                        ...data
                      };
                    }
                    return item;
                  })
                ]
              }
            };
          }
          return subAccount;
        });
        this.selectedUser.user.roleAccess = this.selectedUser.user.roleAccess.map(item => {
          if (item.feature === data.feature) {
            return data;
          }
          return item;
        });
      });
    } catch (error) {
      this.response = error;
    }
    return this.subAccountsList;
  };

  loginAsSubAccount = async data => {
    const response = await loginAsSubAccount(data);
    try {
      runInAction(() => {
        AuthStore.token = response.token;
        AuthStore.isAuthenticated = true;
        cookie.set(AUTH_USER, response.user.email);
        cookie.set(AUTH_TOKEN, response.token);
        cookie.set(REFRESH_TOKEN, response.refreshToken, expireTime);
        setTimeout(() => {
          window.location.href = "/";
        }, 2000);
      });
    } catch (error) {
      runInAction(() => {
        AuthStore.token = AuthStore.token;
        console.log(error);
      });
    }
  };

  checkEmailExists = async email => {
    const response = await checkEmailExists(email);
    try {
      runInAction(() => {
        this.response = response;
      });
    } catch (error) {
      this.response = error;
    }
    return this.response;
  };
}

decorate(ObservableSubAccountStore, {
  inviteUserResponse: observable,
  acceptInviteResponse: observable,
  updateSubAccountResponse: observable,
  removeSubAccountResponse: observable,
  subAccountsList: observable,
  selectedUser: observable,
  total: observable,
  pageSize: observable,
  current: observable,
  search: observable,
  sortField: observable,
  sortOrder: observable,
  assigningGroupResponse: observable,
  assigningTemplateResponse: observable,
  assigningBulkResponse: observable,
  response: observable,
  subAccountPurchaseData: observable
});
export default new ObservableSubAccountStore();
