import React, { useEffect, useState } from "react";
import { observer, inject } from "mobx-react";
import { langRegEx } from "constant/messageConstants";
import UnicodeNotification from "./UnicodeNotification";
import CustomButton from "components/AppButton/CustomButton/CustomButton";

const UnicodeDetector = props => {
  const { message, modalStore, onChange } = props;
  const [allowUnicode, setAllowUnicode] = useState(false);
  function isGSMAlphabet(text) {
    return langRegEx.test(text);
  }

  useEffect(() => {
    if (message && !isGSMAlphabet(message)) {
      showUnicodeNotificationModal();
    }
  }, [message]);

  const showUnicodeNotificationModal = (click = false) => {
    if (allowUnicode && !click) return;
    modalStore.toggleModal("showUnicodeNotificationModal", true);
  };

  return (
    <>
      {message && !isGSMAlphabet(message) && (
        <div className="mb-2">
          <span className="text-danger-color">Unicode Detected</span>
          <CustomButton
            label="Know More"
            onClick={async e => {
              e.preventDefault();
              setAllowUnicode(false);
              showUnicodeNotificationModal(true);
            }}
            type="button"
            className="ms-2"
          />
        </div>
      )}
      <UnicodeNotification
        message={message}
        onChange={onChange}
        setAllowUnicode={setAllowUnicode}
      />
    </>
  );
};

export default inject(stores => ({
  modalStore: stores.store.modalStore
}))(observer(UnicodeDetector));
