import React, { useState, useEffect } from "react";
import { inject, observer } from "mobx-react";
import { Modal } from "antd";
import styles from "assets/css/modals.module.css";
import View from "../View";

const UpgradeModal = props => {
  const { modalStore } = props;

  return (
    <Modal
      destroyOnClose
      zIndex={1001}
      closeIcon={
        <div className={`btn ${styles.modalCloseBtn}`}>
          <i className="fas fa-times"></i>
        </div>
      }
      onCancel={() => {
        modalStore.toggleModal("showUpgrade", false);
      }}
      visible={modalStore.showUpgrade}
      maxWidth={800}
      footer={null}
      centered
      maskStyle={{
        background: "linear-gradient(135deg, #00000050, #107c9850)"
      }}
    >
      <View />
    </Modal>
  );
};
export default inject(stores => ({
  modalStore: stores.store.modalStore
}))(observer(UpgradeModal));
