import { get, post, put, deleteRequest } from "utils/api";

// Daily sms count
export const dailySms = async (params = "") => {
  const response = await get(`/dashboard/totalDaily?${params}`);
  return response.data;
};

// Monthly sms count
export const monthlySms = async (params = "") => {
  const response = await get(`/dashboard/totalMonthly?${params}`);
  return response.data;
};

// Total sms count
export const totalSms = async (params = "") => {
  const response = await get(`/dashboard/total`);
  return response.data;
};

// Checklist API
export const onBoardingChecklist = async () => {
  const response = await get(`/user/onBoarding`);
  return response.data;
};
