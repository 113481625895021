import axios from "axios";

export const getData = async () => {
  try {
    const res = await axios.get("https://geolocation-db.com/json/");
    const isMobile = navigator.userAgentData;
    return {
      ip: res.data?.IPv4,
      country: res.data?.country_name,
      countrycode: res.data?.country_code,
      lat: res.data?.latitude,
      lng: res.data?.longitude,
      platform: isMobile?.platform,
      mobile: isMobile?.mobile,
      browser: isMobile?.brands[2]?.brand
    };
  } catch (er) {
    console.log(er);
    return {};
  }
};
