import React from "react";
import { inject, observer } from "mobx-react";
import { Checkbox, Modal, Row, Col } from "antd";
import AppButton from "components/AppButton/AppButton";
import styles from "assets/css/modals.module.css";
import moment from "moment";

const SelectMergeLabels = props => {
  const { formatText, modalStore, selectedLabels, setSelectedLabels, mergeLabels, setMergeLabels, setData, data } = props;
  return (
    <>
      <Modal
        title={
          <div className="text-center">
            <h4 className="pt-3 fw-bold">Select Merge Labels</h4>
            <p className={`mb-0 ${styles.modalDes}`}>Add Merge Labels to Your Group from the List Below</p>
          </div>
        }
        closeIcon={
          <div className={`btn ${styles.modalCloseBtn}`}>
            <i className="fas fa-times"></i>
          </div>
        }
        visible={modalStore.showAddMergeLabelModal}
        onCancel={() => {
          modalStore.toggleModal("showAddMergeLabelModal", false);
          setSelectedLabels([]);
        }}
        maxWidth={850}
        footer={null}
        centered
        maskStyle={{
          background: "linear-gradient(135deg, #00000050, #107c9850)"
        }}
      >
        <Checkbox.Group
          onChange={value => {
            setSelectedLabels(value);
          }}
          value={selectedLabels}
          className="px-5 overflow-auto shadow-sm w-100"
          style={{
            maxHeight: "600px"
          }}
        >
          <Row gutter={[16, 16]}>
            {mergeLabels.map((item, index) => {
              if (!item?.hide)
                return (
                  <Col span={24} key={index}>
                    <Checkbox value={item.value}>
                      <span className="text-wrap">{formatText(item?.label)}</span>
                    </Checkbox>
                  </Col>
                );
            })}
          </Row>
        </Checkbox.Group>
        <AppButton
          center
          label={"Add Labels"}
          className="mt-3"
          onClick={() => {
            modalStore.toggleModal("showAddMergeLabelModal", false);
            setData([
              ...data,
              ...selectedLabels.map((item, i) => {
                return {
                  id: `s-${i}-${moment().format("hh:mm:ss")}`,
                  oldLabel: item,
                  newLabel: item
                };
              })
            ]);
            setMergeLabels(mergeLabels.filter(item => !selectedLabels.includes(item.value)));
            setSelectedLabels([]);
          }}
          prefixIcon={<i className="fas fa-plus-circle me-2" />}
        />
      </Modal>
    </>
  );
};

export default inject(stores => ({
  modalStore: stores.store.modalStore
}))(observer(SelectMergeLabels));
