import React, { useEffect, useState } from "react";
import { Tabs, Typography, Card, Row, Col } from "antd";
import { inject, observer } from "mobx-react";
// import ExportModal from "components/History/Modal/ExportNotificationModal";
import { messageTypes } from "constant/commonList";

const TabsView = props => {
  const {
    children,
    modalStore,
    isMobile = false,
    tabKey = "sms",
    setTabKey,
    authStore
  } = props;
  const { user } = authStore;
  const [activeKey, setActiveKey] = useState(tabKey);
  const [tabPane, setTabPane] = useState([]);

  useEffect(() => {
    if (
      user?.role === "subaccount" &&
      user?.access.find(access => access === "sms") &&
      user?.access.find(access => access === "mms")
    ) {
      setTabPane(messageTypes);
    } else if (
      user?.role === "subaccount" &&
      user?.access.find(access => access === "sms")
    ) {
      setTabPane([
        {
          value: "sms",
          label: "SMS"
        }
      ]);
      modalStore.setIsMms(false);
      setActiveKey("sms");
    } else if (
      user?.role === "subaccount" &&
      user?.access.find(access => access === "mms")
    ) {
      setTabPane([
        {
          value: "mms",
          label: "MMS"
        }
      ]);
      modalStore.setIsMms(true);
      setActiveKey("mms");
    } else {
      setTabPane(messageTypes);
    }
  }, [user]);

  useEffect(() => {
    setActiveKey(modalStore.isMms ? "mms" : "sms");
    setTabKey && setTabKey(activeKey);
  }, [modalStore.isMms]);

  return (
    <Row gutter={[10, 10]} type="flex" align="middle" justify="center">
      <Col span={24}>
        <Card
          className={`h-100 ${isMobile ? "" : "rounded-xl shadow"}`}
          bordered={false}
          bodyStyle={isMobile ? { padding: 0 } : {}}
        >
          <Tabs
            defaultActiveKey="sms"
            className="campaign-tabs"
            activeKey={activeKey}
            onChange={key => {
              setActiveKey(key);
              modalStore.setIsMms(key === "mms");
              // if (setTabKey) {
              //   setTabKey(key);
              // } else {
              //   modalStore.setIsMms(key === "mms");
              // }
            }}
          >
            {tabPane.map(tabpane => {
              return (
                <Tabs.TabPane
                  tab={
                    <Typography.Title
                      level={4}
                      className="text-info-color px-4"
                    >
                      {tabpane.label}
                    </Typography.Title>
                  }
                  key={tabpane.value}
                  className={`${
                    activeKey == "sms" ? "rounded-ts-0" : ""
                  } bg-white rounded-xl`}
                >
                  {tabpane.value == activeKey ? children : null}
                </Tabs.TabPane>
              );
            })}
          </Tabs>
        </Card>
      </Col>
      {/* {modalStore.showExportNotification && (
        <Col span={24}>
          <ExportModal isMobile={isMobile} limiter />
        </Col>
      )} */}
    </Row>
  );
};

export default inject(stores => ({
  modalStore: stores.store.modalStore,
  authStore: stores.store.authStore
}))(observer(TabsView));
