import React from "react";
import { Drawer, Typography } from "antd";

const AppDrawer = props => {
  const {
    label,
    placement = "right",
    children,
    onClose,
    visible,
    closable = false,
    ...rest
  } = props;
  return (
    <Drawer
      title={
        <Typography.Title level={3} className="mb-0">
          {label}
        </Typography.Title>
      }
      placement={placement}
      closable={closable}
      onClose={onClose}
      visible={visible}
      {...rest}
    >
      {children}
    </Drawer>
  );
};

export default AppDrawer;
