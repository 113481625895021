export const smsPricing = JSON.parse(
  '[{"min":1,"max":500,"aud":5.5},{"min":501,"max":10000,"aud":5},{"min":10001, "max":19999, "aud":4.7},{"min":20000,"max":49999,"aud":4.5},{"min":50000,"max":100000,"aud":4},{"min":100001,"max":250000,"aud":3.5},{"min":250001,"max":1000000,"aud":0 }]'
);

export const smsPricingOnFirstPurchase = JSON.parse(
  '[{"min":1,"max":250000,"aud":2.8},{"min":500001,"max":1000000,"aud":0 }]'
);

export const smsCost = JSON.parse(
  '[{"min":1,"max":27.5,"aud":5.5},{"min":27.6,"max":500,"aud":5},{"min" : 501, "max": 940, "aud" :4.7},{"min":941,"max":2000,"aud":4.5},{"min":2001,"max":2249.95,"aud":4},{"min":16001,"max":8750,"aud":3.5},{"min":8751,"max":2000000,"aud":0}]'
);

export const smsCostOnFirstPurchase = JSON.parse(
  '[{"min":1,"max":8750,"aud":2.8},{"min":8751,"max":2000000,"aud":0}]'
);

export const mmsPricing = JSON.parse(
  '[{"min":1,"max":1000,"aud":39},{"min":1001,"max":3000,"aud":37},{"min":3001,"max":10000,"aud":35},{"min":10001,"max":20000,"aud":33},{"min":20001,"max":50000,"aud":0}]'
);

export const mmsPricingOnFirstPurchase = JSON.parse(
  '[{"min":1,"max":20000,"aud":29},{"min":20001,"max":50000,"aud":0}]'
);

export const mmsCost = JSON.parse(
  '[{"min":1,"max":390,"aud":39},{"min":391,"max":1110,"aud":37},{"min":1111,"max":3500,"aud":35},{"min":3501,"max":6600,"aud":33},{"min":6601,"max":10000,"aud":0}]'
);

export const mmsCostOnFirstPurchase = JSON.parse(
  '[{"min":1,"max":20000,"aud":29},{"min":20001,"max":1000000,"aud":0}]'
);

export const checkFinalPrice = (
  totalMessage,
  isMms = false,
  isFirstBuy = false
) => {
  if (totalMessage <= 0 || totalMessage >= (isMms ? 20001 : 250001)) {
    return 0;
  }
  if (isMms) {
    if (isFirstBuy) {
      return mmsPricingOnFirstPurchase.find(
        x => x.min <= totalMessage && x.max >= totalMessage
      )?.aud;
    } else {
      return mmsPricing.find(
        x => x.min <= totalMessage && x.max >= totalMessage
      )?.aud;
    }
  } else {
    if (isFirstBuy) {
      return smsPricingOnFirstPurchase.find(
        x => x.min <= totalMessage && x.max >= totalMessage
      )?.aud;
    } else {
      return smsPricing.find(
        x => x.min <= totalMessage && x.max >= totalMessage
      )?.aud;
    }
  }
};

export const checkFinalPriceUsingAmount = (
  amount,
  isMms = false,
  isFirstBuy = false
) => {
  if (amount <= 0) {
    return 0;
  }
  if (isMms) {
    if (isFirstBuy) {
      return mmsCostOnFirstPurchase.find(
        x => x.min <= amount && x.max >= amount
      )?.aud;
    } else {
      return mmsCost.find(x => x.min <= amount && x.max >= amount)?.aud;
    }
  } else {
    if (isFirstBuy) {
      return smsCostOnFirstPurchase.find(
        x => x.min <= amount && x.max >= amount
      )?.aud;
    } else {
      return smsCost.find(x => x.min <= amount && x.max >= amount)?.aud;
    }
  }
};

export const otherMessageQty = (cost, isMms = false) => {
  if (isMms) {
    return ((cost * 100) / 5.5).toFixed(0);
  } else {
    return ((cost * 100) / 39).toFixed(0);
  }
};

export const amount = (totalMessage, price) => {
  return parseFloat((totalMessage * price) / 100).toFixed(2);
};

export const includingTaxAmount = (totalMessage, price) => {
  let messageCount = parseFloat(totalMessage) || 0;
  return parseFloat(
    (messageCount * price) / 100 + (messageCount * price) / 1000
  ).toFixed(2);
};

export const discountedCost = (totalMessage, price, isMms = false) => {
  if (totalMessage <= 0 || totalMessage >= (isMms ? 20001 : 250001)) {
    return 0;
  }
  return parseFloat((isMms ? 39 : 5.5) - price).toFixed(2);
};

export const totalSaving = (totalMessage = 0, price, isMms = false) => {
  let savings = 0;

  let messageCount = parseFloat(totalMessage) || 0;
  let totalPrice = parseFloat(price);
  if (messageCount <= 0 || messageCount >= (isMms ? 20001 : 250001)) {
    return savings;
  }
  if (isMms) {
    savings = (messageCount * 39) / 100 - totalPrice;
  } else {
    savings = (messageCount * 5.5) / 100 - totalPrice;
  }
  return savings < 1 ? 0 : parseFloat(savings).toFixed(2);
};

export const buyAutoCredits = async params => {
  const { authStore, userStore, isMms = false } = params;
  const { balanceObj, user } = authStore;
  const { getCards, customPayment } = userStore;
  const balance = parseInt(balanceObj.balance);
  const autoCreditAllowed = user.autoCredit;
  const autoCreditAmount = user.autoCreditAmount;
  const autoCreditRate = user.autoCreditRate || { sms: 5.5, mms: 39 };
  const profileType = user.profileType;
  let cardsList = [];
  let status;

  if (profileType !== "postpaid" && autoCreditAllowed && balance < 10) {
    await getCards().then(({ data }) => {
      if (data.length > 0) {
        cardsList = data;
      }
    });

    if (cardsList.length > 0) {
      let smsCredit = 0,
        mmsCredit = 0;
      mmsCredit = ((autoCreditAmount * 100) / autoCreditRate.mms).toFixed(0);
      smsCredit = ((autoCreditAmount * 100) / autoCreditRate.sms).toFixed(0);
      const values = {
        smsCredit,
        mmsCredit,
        amountWithOutTax: autoCreditAmount.toFixed(2),
        amount: (autoCreditAmount + autoCreditAmount * 0.1).toFixed(2),
        card: cardsList[0].id,
        autoCredit: true
      };
      await customPayment(values).then(status => {
        status = status;
      });
    } else {
      status = false;
    }
  } else {
    status = false;
  }
  return status;
};
