import React from "react";
import styles from "./profile-layout.module.scss";

function ProfileLayoutLoader(props) {
  return (
    <div>
      <div className="d-flex">
        <div className={styles.sidebar}>
          <div className={`d-flex align-items-center p-4`}>
            <div className={styles.image} />
            <div className={`m-3 rounded-pill ${styles.text}`} />
          </div>
          <div className={`my-3 ${styles.group}`}>
            <ul className="list-unstyled ps-3">
              <li className="d-flex align-items-center mb-3">
                <div className={styles.icon} />
                <div className={`ms-3 rounded-pill ${styles.label}`} />
              </li>
              <li className="d-flex align-items-center mb-3">
                <div className={styles.icon} />
                <div className={`ms-3 rounded-pill ${styles.label}`} />
              </li>
              <li className="d-flex align-items-center mb-3">
                <div className={styles.icon} />
                <div className={`ms-3 rounded-pill ${styles.label}`} />
              </li>
              <li className="d-flex align-items-center mb-3">
                <div className={styles.icon} />
                <div className={`ms-3 rounded-pill ${styles.label}`} />
              </li>
              <li className="d-flex align-items-center mb-3">
                <div className={styles.icon} />
                <div className={`ms-3 rounded-pill ${styles.label}`} />
              </li>
              <li className="d-flex align-items-center mb-3">
                <div className={styles.icon} />
                <div className={`ms-3 rounded-pill ${styles.label}`} />
              </li>
            </ul>
          </div>
        </div>
        {props.children}
      </div>
    </div>
  );
}

export default ProfileLayoutLoader;
