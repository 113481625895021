import React from "react";

const SMSCounter = ({
  characters,
  sms,
  credits,
  contacts,
  approx,
  quick,
  limit = 5000,
  isMms = false,
  mobile = false
}) => {
  return (
    <div
      className={`${
        isMms
          ? "mb-2 text-end"
          : mobile
          ? "mb-2 d-flex flex-column justify-content-end mx-2"
          : "mb-2 d-flex flex-row justify-content-end mx-2"
      } ${
        isMms
          ? contacts > credits
            ? "text-danger-color"
            : "text-dark"
          : sms * contacts > credits
          ? "text-danger-color"
          : "text-dark"
      }`}
      style={{ fontSize: "12px" }}
    >
      {!isMms ? (
        <>
          <div className="col-auto p-0 me-2">
            {approx ? (
              <span>{`approx ${characters} Characters`}</span>
            ) : (
              <span>{`${characters} Characters`}</span>
            )}
          </div>
          <div className="col-auto p-0">
            <span>
              {!contacts
                ? `${sms} SMS`
                : `${
                    approx ? `${sms * contacts} SMS` : `${sms * contacts} SMS`
                  }`}
            </span>
          </div>
        </>
      ) : (
        <span className={characters > limit ? "text-danger-color" : ""}>
          {characters} characters {contacts ? contacts + " mms" : ""}
        </span>
      )}
    </div>
  );
};

export default SMSCounter;
