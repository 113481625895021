import { get, post, put, deleteRequest } from "utils/api";

/**
 * Call get all test case api
 * @returns {Promise<any>}
 */
export const getAllBlocks = async (params = {}) => {
  // save api response for Block List list
  const bockListResponse = await get(`/block`, params);
  return bockListResponse.data;
};

/**
 * Add new Block api
 * @param bockData
 * @returns {Promise<any>}
 */
export const addBlock = async (bockData = {}) => {
  const addResponse = await post(`/block`, bockData);
  return addResponse.data;
};

/**
 * Update existing Block Api
 * @param bockData
 * @param bockId
 * @returns {Promise<any>}
 */
export const updateBlock = async (bockData = {}, bockId) => {
  const updateResponse = await put(`/block/${bockId}`, bockData);
  return updateResponse.data;
};
