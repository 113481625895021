import { AUTH_TOKEN, ROLE_TOKEN, REFRESH_TOKEN } from "constant/constant";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import errorIcon from "../../assets/images/modal/alert.svg";
import { BASE_URL } from "constant/apiService";
import { cookie } from "utils/cookie/index";
import axios from "axios/index";
import { Modal } from "antd";
import React from "react";
import { get as _get, has, isEmpty, startsWith } from "lodash";

const secretKey = process.env.API_SECURITY_KEY;
const keyBytes = new Uint8Array(secretKey.match(/.{1,2}/g).map(byte => parseInt(byte, 16)));

async function encryptData(data) {
  try {
    const key = await window.crypto.subtle.importKey("raw", keyBytes, "AES-GCM", false, ["encrypt"]);
    const iv = window.crypto.getRandomValues(new Uint8Array(12));
    const encodedData = new TextEncoder().encode(JSON.stringify(data));
    const encryptedBuffer = await window.crypto.subtle.encrypt({ name: "AES-GCM", iv }, key, encodedData);
    const encryptedData = window.btoa(String.fromCharCode(...new Uint8Array(encryptedBuffer)));
    const ivBase64 = window.btoa(String.fromCharCode(...iv));
    return { encryptedData, iv: ivBase64 };
  } catch (error) {
    console.error("Encryption Error:", error);
    return data;
  }
}

const removeEmptyQueryStrings = url => {
  try {
    const urlObj = new URL(BASE_URL + url);
    [...urlObj.searchParams].forEach(([key, value]) => {
      if (isEmpty(value) || value == "undefined") urlObj.searchParams.delete(key);
    });
    return urlObj.href.replace(BASE_URL, "").toString();
  } catch (error) {
    console.log(error);
    return url;
  }
};

async function encryptUrl(urlPath, params) {
  const queryString = new URLSearchParams(params).toString();
  const path = `${urlPath}?${queryString}`;
  const combinedString = removeEmptyQueryStrings(path);
  const encryptedParams = await encryptData(combinedString);
  return `${BASE_URL}?data=${encodeURIComponent(encryptedParams.encryptedData)}&iv=${encodeURIComponent(encryptedParams.iv)}`;
}
const base64ToUint8Array = base64 => {
  const paddedBase64 = base64
    .replace(/-/g, "+")
    .replace(/_/g, "/")
    .padEnd(base64.length + ((4 - (base64.length % 4)) % 4), "=");
  const decodedData = atob(paddedBase64);
  const uint8Array = new Uint8Array(decodedData.length);
  for (let i = 0; i < decodedData.length; i++) {
    uint8Array[i] = decodedData.charCodeAt(i);
  }
  return uint8Array;
};

async function decryptResponse(data) {
  if (!data || !data.encryptedData || !data.iv || !data.authTag) {
    throw new Error("Missing encryptedData, iv, or authTag in response");
  }
  const { encryptedData, iv, authTag } = data;
  const cryptoKey = await window.crypto.subtle.importKey("raw", keyBytes, "AES-GCM", false, ["decrypt"]);
  const ivBuffer = base64ToUint8Array(iv);
  const encryptedBuffer = base64ToUint8Array(encryptedData);
  const authTagBuffer = base64ToUint8Array(authTag);
  const completeEncryptedBuffer = new Uint8Array(encryptedBuffer.length + authTagBuffer.length);
  completeEncryptedBuffer.set(encryptedBuffer);
  completeEncryptedBuffer.set(authTagBuffer, encryptedBuffer.length);
  const decryptedBuffer = await window.crypto.subtle.decrypt(
    {
      name: "AES-GCM",
      iv: ivBuffer,
      tagLength: 128
    },
    cryptoKey,
    completeEncryptedBuffer
  );
  const decryptedText = new TextDecoder().decode(decryptedBuffer);
  return JSON.parse(decryptedText);
}

const defaultHeaders = {
  Accept: "application/json",
  "Content-Type": "application/json"
};

const authHeader = () => {
  const token = cookie.get(AUTH_TOKEN);
  const roletoken = sessionStorage.getItem(ROLE_TOKEN);
  return { Authorization: `Bearer ${token}`, roletoken };
};

const getHeaders = auth => {
  let headers = { ...defaultHeaders };
  if (auth) {
    headers = { ...headers, ...authHeader() };
  }
  return headers;
};

const url = (path, params) => {
  const sections = path.split(":");
  const sectionsWithParams = sections.map(section => (startsWith(section, "/") ? section : params[section]));
  const pathWithParams = sectionsWithParams.join("");
  return BASE_URL + pathWithParams;
};

const apiService = axios.create({});

// Function that will be called to refresh authorization
const refreshAuthLogic = async failedRequest => {
  return axios
    .post(`${BASE_URL}/auth/accessToken`, {
      accessToken: cookie.get(AUTH_TOKEN),
      refreshToken: cookie.get(REFRESH_TOKEN)
    })
    .then(({ data }) => {
      if (data.data.accessToken) {
        cookie.set(AUTH_TOKEN, data.data.accessToken);
        const newToken = `Bearer ${data.data.accessToken}`;
        failedRequest.response.config.headers["Authorization"] = newToken;
        //apiService.defaults.headers["Authorization"] = newToken;
        return Promise.resolve();
      } else {
        throw Error("Access token not found");
      }
    })
    .catch(error => {
      cookie.erase(AUTH_TOKEN);
      cookie.erase(REFRESH_TOKEN);
      window.location.href = "/login";
      return Promise.resolve();
    });
};

createAuthRefreshInterceptor(apiService, refreshAuthLogic, {
  statusCodes: [401]
});

export const get = (path, params = {}, auth = true, headers = {}) => apiService.get(url(path, params), { params });

export const post = (path, params = {}, auth = true) => apiService.post(url(path, params), params);

export const put = (path, params = {}, auth = true) => apiService.put(url(path, params), params);

export const deleteRequest = (path, params = {}, auth = true) => apiService.delete(url(path, params), { params });

apiService.interceptors.request.use(async request => {
  request.headers = getHeaders(true);
  request.url = await encryptUrl(request.url.replace(BASE_URL, ""), request.params);
  request.params = {};
  if (["post", "put", "delete"].includes(request.method) && request.data && !(request.data instanceof FormData)) {
    const encryptedData = await encryptData(request.data);
    request.data = encryptedData;
  }
  return request;
});

apiService.interceptors.response.use(
  async function(response) {
    if (response.data && response.data?.encryptedData && response.data?.iv) {
      response.data = await decryptResponse(response.data);
    }
    if (has(response.data, "success") && !_get(response.data, "success")) {
      function countDown() {
        let secondsToGo = 5;
        const modal = Modal.warning({
          title: <span className="fw-bold text-center fs-5">Error Occured</span>,
          content: <span className="text-center">{response.data.message}</span>,
          icon: (
            <img
              src={errorIcon}
              style={{
                margin: "0 auto 10px",
                width: "100%",
                height: "50px"
              }}
            />
          ),
          centered: true,
          zIndex: 1031,
          okButtonProps: {
            className: "px-4"
          }
        });
        setTimeout(() => {
          clearInterval();
          modal.destroy();
        }, secondsToGo * 1000);
      }
      countDown();
      // message.error(response.data.message);
      //GAEvent("error", "apiError", "fail", response.data.message, false);
      if (!response.data.message.startsWith("Bad")) {
        return Promise.reject(_get(response.data, "message"));
      }
    } else if (has(response.data, "status") && !_get(response.data, "status")) {
      function countDown() {
        let secondsToGo = 5;
        const modal = Modal.warning({
          title: <span className="fw-bold text-center fs-5">Error Occured</span>,
          content: <span className="text-center">{response.data.message}</span>,
          icon: (
            <img
              src={errorIcon}
              style={{
                margin: "0 auto 10px",
                width: "100%",
                height: "50px"
              }}
            />
          ),
          centered: true,
          zIndex: 1031,
          okButtonProps: {
            className: "px-4"
          }
        });
        setTimeout(() => {
          clearInterval();
          modal.destroy();
        }, secondsToGo * 1000);
      }
      countDown();
      // message.error(response.data.message, 8);
    }
    return response;
  },
  function(error) {
    if (error.response && error.response.data && error.response.data.message) {
      //GAEvent("error", "apiError", "fail", error.response.data.message, false);
      if (!error.response.data.message.startsWith("Bad")) {
        function countDown() {
          let secondsToGo = 5;
          const modal = Modal.warning({
            title: <span className="fw-bold text-center fs-5">Error Occured</span>,
            content: <span className="text-center">{error.response.data.message}</span>,
            icon: (
              <img
                src={errorIcon}
                style={{
                  margin: "0 auto 10px",
                  width: "100%",
                  height: "50px"
                }}
              />
            ),
            centered: true,
            zIndex: 1031,
            okButtonProps: {
              className: "px-4"
            }
          });
          setTimeout(() => {
            clearInterval();
            modal.destroy();
          }, secondsToGo * 1000);
        }
        countDown();
        // message.error(error.response.data.message);
        if (error.response.data.message === "Email already exists, Please login") {
          window.location.href = "/";
        }
      }
    }
    return Promise.reject(error.response);
  }
);
