import React from "react";

const SpinnerLoader = () => {
  return (
    <div className="text-center">
      <span className="ant-spin-dot ant-spin-dot-spin">
        <i className="ant-spin-dot-item"></i>
        <i className="ant-spin-dot-item"></i>
        <i className="ant-spin-dot-item"></i>
        <i className="ant-spin-dot-item"></i>
      </span>
    </div>
  );
};

export default SpinnerLoader;
