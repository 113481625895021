import React, { useEffect, useState, useRef } from "react";
import { Modal, Typography, Col, Row, Tooltip } from "antd";
import styles from "assets/css/modals.module.css";
import { Formik } from "formik";
import { Form } from "antd";
import * as Yup from "yup";
import AppButton from "components/AppButton/AppButton";
import { inject, observer } from "mobx-react";
import { validAusNumberRegExpDeluxe, getFormatedContact, getFormatedContacts } from "constant/messageConstants";
import ContactSelector from "components/GatewayMessage/ContactSelector/ContactSelector";
import { isEmpty } from "lodash";

const validationSchema = Yup.object().shape({
  contacts: Yup.array()
    .of(
      Yup.string()
        .matches(validAusNumberRegExpDeluxe, "Invalid phone number")
        .nullable(true)
      // .required('Please add atleast one number')
    )
    .min(1, "Please add atleast one number")
    .max(20, "Maximum 20 numbers allowed")
    .nullable(true)
});

const TestSMS = props => {
  const { isVisible, handleCancel, sendGatewayMessage, authStore, isMms, name = "message", skip = false, isFirstTime, values, saveAndContinue, quickMessage = false, modalStore, contactStore } = props;

  const user = authStore.user;

  const formikRef = useRef();

  useEffect(() => {
    isVisible && user && user.mobile && modalStore.setData("quickSMSContact", getFormatedContact(user.mobile));
  }, [isVisible, user]);

  useEffect(() => {
    if (modalStore.isMms) {
      try {
        let defaultNum = modalStore.quickSMSContact
          ? getFormatedContacts([modalStore.quickSMSContact])
          : user && user.firstQuickMMS && user.mobile
          ? [...getFormatedContacts([user?.mobile]), ...formikRef.current?.values?.contacts].filter((item, index, self) => self.indexOf(item) === index)
          : formikRef.current?.values?.contacts;
        if (isEmpty(defaultNum)) defaultNum = [user.mobile];

        formikRef.current?.setFieldValue("contacts", defaultNum);
        contactStore.setSearchContacts(
          [
            {
              label: defaultNum?.[0],
              value: defaultNum?.[0],
              number: defaultNum?.[0]
            }
          ],
          true
        );
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        let defaultNum = modalStore.quickSMSContact
          ? getFormatedContacts([modalStore.quickSMSContact])
          : user && user.firstQuickSMS && user.mobile
          ? [...getFormatedContacts([user?.mobile]), ...(formikRef.current?.values?.contacts ? formikRef.current?.values?.contacts : [])].filter((item, index, self) => self.indexOf(item) === index)
          : formikRef.current?.values?.contacts;
        if (isEmpty(defaultNum)) defaultNum = [user.mobile];

        formikRef.current?.setFieldValue("contacts", defaultNum);
        contactStore.setSearchContacts(
          [
            {
              label: defaultNum?.[0],
              value: defaultNum?.[0],
              number: defaultNum?.[0]
            }
          ],
          true
        );
      } catch (error) {
        console.log(error);
      }
    }
    if (formikRef.current?.resetForm) modalStore.setReset(formikRef.current?.resetForm);
  }, [modalStore.quickSMSContact]);

  if (!user) return null;

  const initialValues = { ...values, contacts: user?.mobile ? [user?.mobile] : [] };

  return (
    <Modal
      destroyOnClose
      centered
      title={
        <div className="text-center">
          <h4 className="pt-3 fw-bold">Test {isMms ? "MMS" : "SMS"}</h4>
        </div>
      }
      closeIcon={
        <div className={`btn ${styles.modalCloseBtn}`}>
          <i className="fas fa-times"></i>
        </div>
      }
      visible={isVisible}
      onCancel={() => handleCancel()}
      width={600}
      footer={null}
      maskStyle={{
        background: "linear-gradient(135deg, #00000050, #107c9850)"
      }}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          if (values?.numberList) delete values.numberList;
          await sendGatewayMessage(values);
          contactStore.setSearchContacts();
          setSubmitting(false);
        }}
        innerRef={formikRef}
      >
        {({ values, errors, touched, setFieldValue: setFieldValueFormik, handleSubmit, isSubmitting }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <Row gutter={[20, 20]} type="flex" justify="center">
                <Col md={20} span={24} className="text-start mb-5">
                  <div className="d-flex justify-content-center">
                    <Typography.Title level={4} className="fs-6">
                      Enter the Numbers
                    </Typography.Title>
                  </div>
                  <ContactSelector
                    isTestSms={true}
                    containerProps={{ colon: false }}
                    name="contacts"
                    errors={errors}
                    touched={touched}
                    values={values}
                    setFieldValue={setFieldValueFormik}
                    onChange={e => {
                      if (!values.contacts.includes(getFormatedContacts(e)[e.length - 1])) setFieldValueFormik("contacts", getFormatedContacts(e));
                    }}
                    onDeselect={e => {
                      if (values.contacts.includes(e))
                        setFieldValueFormik(
                          "contacts",
                          values.contacts.filter(i => i !== e)
                        );
                    }}
                    containerClassnames="mb-0"
                    secondary={false}
                  />
                </Col>
                {/* {!quickMessage && editMessage ? (
                  <MessageContainer
                    label={
                      <div className="d-flex justify-content-between">
                        <Typography.Title level={4} className="fs-6">
                          Edit Message
                        </Typography.Title>
                        <Tooltip title="Save Content">
                          <i
                            className="fas fa-save"
                            onClick={() => handleEditContent(false)}
                          ></i>
                        </Tooltip>
                      </div>
                    }
                    colSpan={24}
                    colMd={20}
                    formikRef={textAreaRef}
                    setFieldValue={setFieldValueFormik}
                    handleChange={handleChange}
                    errors={errors}
                    touched={touched}
                    values={values}
                    name={"message"}
                    optOut={values?.optType}
                    isMms={isMms}
                    campaign
                    scheduler={false}
                    testSMS={false}
                    optoutCheckBox={false}
                  />
                ) : (
                  <Col md={20} span={24} className="text-center">
                    <div className="d-flex justify-content-between">
                      <Typography.Title level={4} className="fs-6">
                        Message Content
                      </Typography.Title>
                      {!quickMessage && (
                        <Tooltip title="Edit Message">
                          <i
                            className="fas fa-edit"
                            onClick={() => handleEditContent(true)}
                          ></i>
                        </Tooltip>
                      )}
                    </div>
                    <Card
                      className="rounded border-info-color pe-auto"
                      onClick={() => handleEditContent(true)}
                      bodyStyle={{
                        padding: "1rem"
                      }}
                    >
                      <Typography.Paragraph
                        className="text-break text-start"
                        ellipsis={{
                          rows: 6,
                          expandable: true,
                          symbol: "more"
                        }}
                        style={{ whiteSpace: "break-spaces" }}
                      >
                        {values.message}
                        <br />
                        {values?.replyStopToOptOut && replyStopMessageSample}
                        {values?.optout && optOutMessageSample}
                      </Typography.Paragraph>
                    </Card>
                  </Col>
                )} */}
                <Col md={20} span={24} className="text-center mt-5">
                  <AppButton
                    label={isFirstTime ? `Send Test ${isMms ? "MMS" : "SMS"}` : `Resend Test ${isMms ? "MMS" : "SMS"}`}
                    type="submit"
                    light
                    withoutBg={!isFirstTime && !quickMessage}
                    disabled={isSubmitting}
                  />
                  {(skip || (saveAndContinue && !isFirstTime && !quickMessage)) && (
                    <AppButton
                      label={!isFirstTime ? "Next" : "Skip"}
                      type="button"
                      light
                      withoutBg={isFirstTime}
                      className="ms-3"
                      onClick={() => {
                        saveAndContinue();
                        handleCancel();
                      }}
                    />
                  )}
                </Col>
              </Row>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default inject(stores => ({
  authStore: stores.store.authStore,
  modalStore: stores.store.modalStore,
  contactStore: stores.store.contactStore
}))(observer(TestSMS));
