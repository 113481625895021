import { Col, Empty, Row, Typography } from "antd";
import React, { useEffect, useState } from "react";
import ProductCard from "./ProductCard";
import { inject, observer } from "mobx-react";

const Bundles = props => {
  const { userStore, modalStore, fontColor, handleBuyNow, isFirstBuy } = props;
  const [allProducts, setAllProducts] = useState([]);
  const [productList, setProductList] = useState([
    {
      name: "Guni SMS",
      loading: true
    },
    {
      name: "Guni SMS",
      loading: true
    },
    {
      name: "Guni SMS",
      loading: true
    },
    {
      name: "Guni SMS",
      loading: true
    }
  ]);

  useEffect(() => {
    userStore.getAllGatewayPlans().then(res => {
      let tempList = [];
      tempList = res
        .filter(item => item.metadata.platform === "gunisms")
        .sort((a, b) => a.price.unit_amount - b.price.unit_amount)
        .map(item => {
          return {
            id: item.id,
            name: item.name,
            price: item.price.unit_amount,
            loading: false,
            ...item.metadata
          };
        });
      setAllProducts(tempList);
    });
  }, []);

  useEffect(() => {
    const tempList = allProducts.filter(
      item => item.type === (modalStore.isMms ? "mms" : "sms")
    );
    setProductList(tempList);
  }, [allProducts, modalStore.isMms]);

  return (
    <Row gutter={[16, 16]} type="flex" justify={"center"}>
      <Col span={24}>
        <Typography.Title
          style={fontColor}
          level={3}
          className="text-uppercase text-center gotham-bold mt-4"
        >
          {modalStore.isMms ? "MMS" : "SMS"} Bundle Packs
          {isFirstBuy && (
            <Typography.Paragraph>
              <span
                style={{ backgroundColor: "#5ddafa52" }}
                className="fs-6 rounded p-2 text-capitalize"
              >
                ✨ Special Discounted Price on First Purchase ✨
              </span>
            </Typography.Paragraph>
          )}
        </Typography.Title>
      </Col>
      {productList.length > 0 ? (
        productList.map((item, index) => (
          <Col
            xl={modalStore.quickMessage ? 8 : 6}
            md={modalStore.quickMessage ? 12 : 8}
            span={12}
            key={index}
          >
            <ProductCard
              fontColor={fontColor}
              data={item}
              loading={item.loading}
              bestseller={item.bestseller}
              handleBuyNow={handleBuyNow}
              isMms={modalStore.isMms}
              isFirstBuy={isFirstBuy}
            />
          </Col>
        ))
      ) : (
        <Col span={10} className="text-center">
          <Empty
            style={{
              borderRadius: "25px",
              maxWidth: "220px",
              minHeight: "230px"
            }}
            className="mx-auto my-0"
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={<span>No Bundle Found</span>}
          />
        </Col>
      )}
    </Row>
  );
};

export default inject(stores => ({
  modalStore: stores.store.modalStore,
  userStore: stores.store.userStore
}))(observer(Bundles));
